import { useEffect, useState } from "react";
import Server from "./../Server";
import PostDetails from "./PostDetails";
export default function MarketDetail(){
    const [detail, setDetail] = useState([])
    const [history, setHistory] = useState([])
    useEffect(()=>{
        Server.post("/Post/MarketDetail", {Username: localStorage.getItem("Username")}).then(
            res=> {setDetail(res.data.reverse())}
        );
        Server.post("/Manage/Delivery_Status_Marketer", {Username: localStorage.getItem("Username")}).then(res=>{
            setHistory(res.data)
        })
    }, []);

    const update = (id)=>{
        Server.post("/Manage/Updating_Delivery_Status_Marketer", { Id: id }, {timeout: 30000}).then(
            res=>{
                if(res.data === "updated"){
                    window.location.reload()
                }
            }
        )
    }
    return(
        <section>
            {
                detail.length > 0 && detail.map((val, index)=>{
                    return(
                        <PostDetails key={index} list={[val]} quantityAsked={val.RequestedAmount} preparing={[val.Id]} Asker={val.Asker} Status={val.Status} AskedQuantity={val.AskedQuantity}/>
                    )
                })
            }
            {
                detail.length === 0 &&
                <section style={{textAlign: "center", width: "100%"}}>
                !لە ئێستادا کەس داوای کاڵاکانی نەکردووی
                </section>
            }
            <section className="table-responsive-sm">
            <table className="table table-striped table-hover table-bordered table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Total Sold</th>
                        <th>Tax</th>
                        <th>Shopper</th>
                        <th>Shopper Title</th>
                        <th>Shopper Phone</th>
                        <th>Delivered By</th>
                        <th>Delivered At</th>
                        <th>Profit</th>
                        <th>Total Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        history.length > 0 && history.map((elem, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{elem.Id}</td>
                                    <td>{elem.Title}</td>
                                    <td>{elem.Price}</td>
                                    <td>{elem.Total_Sold}</td>
                                    <td>{elem.Tax}</td>
                                    <td>{elem.ShopperUsername}</td>
                                    <td>{elem.ShopperTitle}</td>
                                    <td>{elem.ShopperPhone}</td>
                                    <td>{elem.Delivered_By}</td>
                                    <td>{elem.Delivered_Date.toLocaleString()}</td>
                                    <td>{elem.Profit}</td>
                                    <td>{((elem.Total_Sold * elem.Price) - elem.Profit).toLocaleString()}</td>
                                    <td>{elem.Status}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </section>
        </section>
    )
}