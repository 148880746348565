import { useEffect, useState } from "react"
import Server from "./../../../Server"
export default function PromotionDetail(){
    const id = localStorage.getItem("selectedPromotion")
    const [detail, setDetail] = useState({})
    useEffect(()=>{
        const load = async()=>{
            await Server.post("/Manage/PromotionDetail", {Id: id}).then(res=>setDetail(res.data)).catch(res=>{console.log(res)})
        }
        load()
    }, [id])
    const ready = detail[0];
    const LogoImg = `https://future-dev.tech/server/Promotions/${ready?.Username}/${ready?.Logo}`;
    const IdImg = `https://future-dev.tech/server/Promotions/${ready?.Username}/${ready?.Identification}`;
    const Action = (action) =>{
        switch(action){
            case "Accept":
                Server.post("/Manage/AcceptPromotion", {Username : ready?.Username, Role : ready.Role}).then(res=>{
                    if(res.data === "Success"){
                        window.location="./../Manage"
                    }
                }).catch(res=>console.log(res))
                break;
            case "Deny":
                Server.post("/Manage/DenyPromotion", {Id : ready?.Id}).then(res=>{
                    if(res.data === "Success"){
                        window.location="./../Manage"
                    }
                }).catch(res=>console.log(res))
                break;
            default:
                console.error("Error")
        }
    }
    return(
        <>
            <button className="btn btn-primary" onClick={()=>{window.location="./../Manage"}}>Go Back</button>
            {
            ready != null && <section>
                <section>
                    {ready.Username}
                </section>
                <section>
                    {ready.Title}
                </section>
                <section>
                    {ready.Description}
                </section>
                <section>
                    {ready.City}
                </section>
                <section>
                    {ready.Role}
                </section>
                {ready.Role !== "Delivery" && 
                <section>
                    <img width="400" height="200" src={LogoImg} alt="Logo" style={{objectFit: "contain"}}/>
                </section>
                
                }
                <section>
                    <img width="400" height="200" src={IdImg} alt="ID" style={{objectFit: "contain"}}/>
                </section>
                <section className="mt-2">
                    <button className="btn btn-primary" onClick={()=>{Action("Accept")}}>Accept</button>
                    <button className="btn btn-primary ms-2" onClick={()=>{Action("Deny")}}>Deny</button>
                </section>
            </section>
            }
        </>
    )
}