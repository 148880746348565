import { useEffect, useState } from "react"
import Server from "./../../../Server"
import * as Icon from "react-bootstrap-icons"
export default function Promotions(){
    const [detail, setDetail] = useState(null)
    useEffect(()=>{
        Server.get("/Manage/Promotions").then(res=>setDetail(res.data)).catch(res=>console.error(res))
    }, [])
    const Direct = (Id)=>{
        localStorage.setItem("selectedPromotion", Id)
        window.location = "/Manage/PromotionDetail";
    }
    return (
        <section className="table-responsive-sm">
        <table className="table table-striped table-hover table-bordered table-sm">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Username</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>City</th>
                        <th>Logo</th>
                        <th>Identification</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
            {
                detail?.map((val, index)=>{
                    const Logo = `https://future-dev.tech/server/Promotions/${val.Username}/${val.Logo}`;
                    const IdImg = `https://future-dev.tech/server/Promotions/${val.Username}/${val.Identification}`;
                    return(
                        <tr key={index}>
                        <td className="Id">
                            {val.Id}
                        </td>
                        <td>
                            {val.Username}
                        </td>
                        <td>
                            {val.Title || "N/A"}
                        </td>
                        <td>
                            {val.Description || "N/A"}
                        </td>
                        <td>
                            {val.City || "N/A"}
                        </td>
                        <td>
                            <img width="30" height="30" alt="Logo" src={Logo} style={{objectFit: "contain"}}/>
                        </td>
                        <td>
                            <img width="30" height="30" alt="Id" src={IdImg} style={{objectFit: "contain"}}/>
                        </td>
                        <td>
                            {val.Role || "N/A"}
                        </td>
                        <td>
                            <section>
                                <Icon.InfoCircle className="Edit text-primary me-2" onClick={()=>{Direct(val.Id)}}/>
                            </section>
                        </td>
                    </tr>
                    )
                })
            }
                </tbody>
            </table>
            </section>
    )
}