import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Server from "./../Server";
import PostDetails from './PostDetails';

export default function SinglePost(){
    const [detail, setDetail] = useState();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const Id = params.get('Id');
    const Location = params.get('Location')
    const Username = params.get("Username")

    useEffect(()=>{
        console.log("Component renders")
        const fetchData = async ()=>{
            const response = await Server.post("/Post/SinglePost", {Id: Id, Location: Location, Username: Username})
            setDetail(response.data)
        }
        fetchData();
    }, [Id, Location, Username])
    return(
        <>
            { detail && <PostDetails list={detail} location="Order" orderButton={false} addCart={true} readyToCheckOut={true} Post_Id={Id}/>}
        </>
    )
}