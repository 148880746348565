import * as Icon from "react-bootstrap-icons";
import "./../css/SearchBar.css";
import { useEffect, useState } from "react";
import Server from "./../Server";
import Swal from "sweetalert2";
export default function SearchBar(){
    const [number, setNumber] = useState({total:0});
    const updateCart = (data)=>{
        setNumber(data);
        localStorage.setItem("Items_In_Cart", JSON.stringify(data))
        localStorage.setItem("Cart_Need_Update", 'false');
    }
    let cart_Need_Update = localStorage.getItem("Cart_Need_Update")
    useEffect(()=>{   
        if(localStorage.getItem("Cart_Need_Update") === 'true'){
            Server.post("/Cart/getTotalItemInCart", {Username: localStorage.getItem("Username")}).then(res=>updateCart(res.data)) 
        }
        setTimeout(()=>{
            console.log(localStorage.getItem("Items_In_Cart"))
            if(!localStorage.getItem("Items_In_Cart")){
                return;
            }
            setNumber(JSON.parse(localStorage.getItem("Items_In_Cart")))
    
        }, 1000)
    },[cart_Need_Update])

    const HeartAnimation = (trigger) =>{
        let heartFill = document.getElementById("HeartFill")
        let heart = document.getElementById("Heart")
        if(trigger){
            heart.style.visibility = "hidden";
            heartFill.style.visibility = "visible";
        }else{
            heart.style.visibility = "visible";
            heartFill.style.visibility = "hidden";
        }
    }
    const Focusing = (trigger) =>{
        let search = document.getElementById("searchbar");
        if(trigger){
            search.style.width = "90%";
        }else{
            search.style.width = "35%";
        }
    }
    const path = window.location.pathname;
    const direct = ()=>{
        if(localStorage.getItem("Username")){
            window.location='Favorite';
        }
    }
    const [search, SetSearch] = useState({
        input : ""
    })
    const handleChange = (e)=>{
        const {name, value} = e.target
        SetSearch({...search, [name]: value})
    }
    const submit = ()=>{
        localStorage.setItem("search", search.input);
        window.location = "Search";
    }
    return (
        <>
        <section className="d-flex align-items-center justify-content-between" id="searchTop">
            <section className="d-flex flex-row align-items-center" id="searchbar" onFocus={()=>{Focusing(true)}} onBlur={()=>{Focusing(false)}}>
                <Icon.Search onClick={submit}/>
                <input type="search" name="input" value={search.input} onChange={handleChange}/>
            </section>
        <button type="button" className="btn position-relative">
            <Icon.Cart2 size={22} onClick={()=>{if(localStorage.getItem("Username")){window.location ="./Cart"}else{
                Swal.fire({
                    icon: "error",
                    title: "سەرەتا هەژمارەکەت بکەرەوە",
                    showClass: {
                      popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                      `
                    },
                    hideClass: {
                      popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                      `
                    },
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "کردنەوەی هەژمار",
                    denyButtonText: `وازهێنان`
                  }).then((result) => {
                    
                    if (result.isConfirmed) {
                      window.location = "/Login";
                    }
                  });
            }}}/>
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {number.total || 0}
            <span className="visually-hidden">unread messages</span>
            </span>
            </button>
            <section style={{position: "relative", display:"none"}} onMouseEnter={()=>HeartAnimation(true)} onMouseLeave={()=>HeartAnimation(false)}>
                {
                    path === "/Favorite" ? <Icon.HeartFill id="HeartFill" color="red" size={22} cursor={"pointer"} onClick={direct}/> :
                <>
                <Icon.Heart color="transparent" size={22} cursor={"pointer"} onClick={()=>{}}/>
                <Icon.HeartFill id="HeartFill" color="red" size={22} cursor={"pointer"} onClick={direct} style={{position:"absolute", left:"0", visibility:"hidden"}}/>
                <Icon.Heart id="Heart" color="red" size={22} cursor={"pointer"} onClick={direct} style={{position:"absolute", left: "0"}}/>
                </>
                }
            </section>
        </section>
        </>
    )
}