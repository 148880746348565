import { useEffect, useState } from "react"
import PostDetails from "../../PostDetails";
import Server from "./../../../Server"

export default function Posts(){
    const [detail, setDetail] = useState([])
    useEffect(()=>{
        Server.post("/manage/AllPosts", {Role : localStorage.getItem("Role")}).then(res=>{
            setDetail(res.data);
        })
    }, [])
    return (
        <>
            {
                detail.length > 0 && detail.map((elem, index)=>{
                    return(
                        <section key={index}>
                            <PostDetails list={[elem]} owner={elem.Id} tax={elem.Id}/>
                        </section>
                    )
                })
            }
        </>
    )
}