import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Server from "./../Server";


export default function SinglePost(){
    const [detail, setDetail] = useState([]);
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const Id = params.get('Id');


    useEffect(()=>{
        const fetchData = async ()=>{
            const response = await Server.post("./stories/SingleStory", {Id: Id})
            await Server.post("/stories/addView", { Id: Id });
            setDetail(response.data)

        }
        fetchData();
    }, [Id])
    let file = '';
    if(detail !== null || detail !== undefined){
        file = `https://future-dev.tech/server/StoryVideos/${detail[0].Username}/${detail[0].Filename}`;
    }
    return(
        <>
            { (detail !== null || detail !== undefined) && <video width="400" height="300" src={file} controls={true}/>}
        </>
    )
}