import * as Icon from "react-bootstrap-icons";
import Server from "./../Server";
import { useEffect, useState } from "react"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"
import "./../css/PostDetails.css"
import Swal from "sweetalert2";
export default function PostDetails({list, location, orderButton, addCart, logo, RemoveFromCart, Post_Id, owner, quantityAsked, size, type, tax, editContent, ReadyToEdit, preparing, Asker, Status, AskedQuantity}){
    const [quantity, setQuantity] = useState(1);
    const [emails, setEmails]= useState("");
    const [formData, setFormData] = useState({
        id : list[0]?.Id,
        availableContent : list[0]?.Quantity || 0,
        price : list[0]?.Price || 0,
        title : list[0]?.Title || "",
        description : list[0]?.Description || ""
    })

    useEffect(() => {
        if (list && list.length > 0) {
            setFormData({
                id : list[0]?.Id,
                availableContent: list[0]?.Quantity || 0,
                price: list[0]?.Price || 0,
                title: list[0]?.Title || "",
                description: list[0]?.Description || ""
            });
        }
    }, [list]);
    
    const [Tax, setTax] = useState(0);
    useEffect(()=>{
        if(tax > 0){
            setTax(list.filter(elem=> elem.Id===tax)[0].Tax)
        }
    }, [tax, list])
    const updateTax = ()=>{
        Server.post("/Tax/updateSinglePost", {Id: tax, newTax: Tax}).then(res=>{
            if(res.data === "success"){
                alert("Updated");
            }
        })
    }
    const handleQuantity = (e)=>{
        try{
            const { value } = e.target
            if(value < 1){
                setQuantity(value);
                
            }else if(value > list[0].Quantity){
                setQuantity(list[0].Quantity);
                
            }else{
                setQuantity(value);
            }
            console.log(e)
        }catch(error){
            console.log(error)
        }
    }
    const toggleFavorite = (Id, Location)=>{
        if(localStorage.getItem("Username")){
            Server.post("/Post/removeFavorite", {Id: Id, Username: localStorage.getItem("Username"), Location: Location}).then(res=>{
                if(res.data === "Success"){
                    window.location.reload()
                }
                }).catch(res=>console.log(res))
        }
    }
    let username = localStorage.getItem("Username");
    const order = (id)=>{
        if(location){
            window.location = `./SinglePost?Id=${id}&Location=${location}&Username=${localStorage.getItem("Username")}`
        }else{
            window.location = `./SinglePost?Id=${id}`
        }
    }
    const AddToCart = (id)=>{
        Server.post("/Profile/Agreement", {Username: localStorage.getItem("Username")}).then(
            res=>{
                if(res.data === "Has not accepted"){
                    window.location.reload();
                }else{
                    if(quantity > 0 && res.data !== "Has not accepted"){
                        Server.post("/Cart/addCart", {Id: id, Username: username, Quantity: quantity}).then(async res=>{
                            if(res.data === "Confirm"){
                                await new Promise(resolve=>{
                                    localStorage.setItem("Cart_Need_Update", 'true')
                                    resolve();
                                }).then(res=>{
                                   window.location.reload()
                                })
                            }
                        });
                    }
                }
            }
        )
    }
    const RemoveCart = async ()=>{
        Server.post("/Cart/removeCart", {Id: RemoveFromCart, Username: username}).then(async res=>{
            if(res.data === "Confirm"){
                await new Promise(resolve=>{
                    localStorage.setItem("Cart_Need_Update", 'true')
                        resolve();
                }).then(res=>{
                    window.location.reload();
                })
            }
        })
    }
    const deletePost = (username, file)=>{
        confirmAlert({
            title: 'ئاگاداربە',
            message: 'گەر ئەم پۆستە بسڕییتەوە ئەوا ناتوانی بیگەڕێنیتەوە، دڵنیایت بە سڕینەوە؟',
            buttons: [
              {
                label: 'بەڵێ',
                onClick: () => {
                    Server.post("/Profile/deletePost", {Id: owner, Username: username, FileName: file}).then(
                        res=>{
                            if(res.data === "Confirm" || res.data === "File deleted successfully"){
                                window.location.reload();
                            }
                        }
                    )
                }
              },
              {
                label: 'نەخێر',
                onClick: () =>{}
              }
            ]
          });
    }
    function convertUtcToLocal(utcString) {
        // Create a Date object with the UTC time
        const date = new Date(utcString + 'Z'); // Adding 'Z' to indicate UTC time  
        // Format the date to a readable format (optional, you can adjust the formatting)
        return date.toLocaleString('en-US', {
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit'
        });
    }  
    const handleChanges = (e)=>{
        const { value, name } = e.target;
        setFormData({...formData, [name]:value});
    }
    const updatePost = (id)=>{
        sessionStorage.setItem("ReadyToUpdate", id);
        window.location = './ReadyToUpdate';
    }
    const SendUpdate = ()=>{
        if(Number.isInteger(formData.availableContent) && Number.isInteger(formData.price)){
            Server.post("/Post/UpdatePost", formData).then(res=>{
                if(res.data === "successful"){
                    window.location.reload()
                }
            })
        }

    }
    const Prepared = async ()=>{
        try{
            const emailSet = new Set(emails.split(', ').filter(email => email));
            Server.post("/Delivery/Prepared", { Id: preparing})
            for (const elem of preparing) {
                const res = await Server.post("/Cart/getEmailsForNotification", { Id: elem });
                res.data.forEach(item => {
                    emailSet.add(item.Email);
                    emailSet.add(item.Delivery);
                });
            }
            setEmails(Array.from(emailSet).join(', '));
            const data = {
                To : emails,
                Subject : "داواکاری گەیاندنت بۆ هاتووە",
                HTMLContent : `
                <section style='text-align:right'>
                <h2>سڵاو</h2>
                <p>${username}:بەکارهێنەر </p>
                <p>داوای چەند کاڵایێکی کردووە بۆ گەیاندن</p>             
                <a href="https://daimshop.future-dev.online/Delivery">سەیرکردنی کاڵاکان</a>
                </section>
                `
            }
            Server.post("/Email/send-email", data).then(res=>{console.log(res.data)});
        }catch{
            Swal.fire({
                icon: "error",
                title: "کێشەیەکی نەزانراو ڕوویدا، تکایە دووبارە هەوڵبەرەوە",
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                },
                showCancelButton: false,
                confirmButtonText: "باشە",
              })
        }finally{
            window.location.reload();
        }
    }
    return(
        <>
        {list?.map((element, key)=>{
                const file = `https://future-dev.tech/server/PostAssets/${element.Username}/${element.File}`;
                let profile  = `https://future-dev.tech/server/ProfileImage/${element.Username}/profile.jpg`;
                return(
                    <section key={key} className={size === "small" ? "MarketPosts small" : "MarketPosts"}> 
                                <section style={{position:"relative"}}>
                                {
                                    element.Quantity <= 0 && 
                                    <section className="alreadySold">
                                        <p>
                                            فرۆشراوە
                                        </p>
                                    </section>
                                } 
                                    <section style={{textAlign:"left", marginLeft: "10px", marginBottom:"10px", display: type==="daimshop"?"none":"block"}} className="id">
                                        #DS{element.Id}
                                    </section>
                                    {
                                        owner && 
                                        <section style={{textAlign: "right", marginRight: "10px"}} onClick={()=>{deletePost(element.Username, element.File)}}>
                                            <Icon.XCircle size={22} color="red" className="remove"/>
                                        </section>
                                    }
                                    { RemoveFromCart && <Icon.XCircle onClick={RemoveCart} color="red" size={22} style={{float: "right", padding: "0", marginRight: "10px"}} className="remove"/>  }             
                                    <section style={{display:"flex", justifyContent: "space-between", alignItems:"center", padding: "0 0.7em"}} className="postHeader">
                                        <span style={{display: type==="daimshop"?"none":"block"}}><img style={{float:"left", width:"40px", aspectRatio: "1/1"}} src={profile} alt="profile"/></span><span style={{margin: "0 auto"}}>{element.Username}</span>
                                    </section>

                                    <section style={{float: "left", fontSize: "10pt", marginLeft: "10px", display: type === "daimshop"? "none": "block"}} className="date">
                                                {convertUtcToLocal(element.PublishedDate)}
                                    </section>

                                    <section style={{position: "relative", padding:"10px"}}>
                                        <section style={{position: "absolute", top: "5px", right: "5px", display: "none"}}>
                                          <Icon.HeartFill color="red" size={22} cursor={"pointer"} onClick={()=>{toggleFavorite(element.Id, element.Location)}}/>
                                        </section>
                                        <img src={file} alt="Item"/>
                                        {
                                            editContent ? <input name="title" onChange={handleChanges} value={formData.title} className="editContent"/>:
                                            <span>
                                                {element.Title}
                                            </span>  

                                        }
                                        <section style={{alignSelf: "flex-end", display: logo? "none": "block"}}>
                                            <p>نرخ: {editContent? <input  className="editContent" name="price" value={formData.price} type="number" min="0" step="1" onChange={handleChanges}/> :<span>{element.Price}</span>} دیناری ئێراقی</p>                                        
                                        </section>
                                        <span style={{textAlign: "center", display: type === "daimshop" || orderButton ? "none":  "block"}}>{editContent ? <input className="editContent" name="description" value={formData.description} onChange={handleChanges} /> : element.Description}</span>
                                        <p dir="rtl" style={{textAlign: "right", display: logo? "none": "block"}}>ژمارەی بەردەست: {editContent? <input className="editContent" name="availableContent" value={formData.availableContent} onChange={handleChanges} type="number" min="0" step="1"/> :<span>{element.Quantity >= 0 ? element.Quantity : 0}</span>}</p>
                                        <p style={{textAlign: "right", display: logo? "none": "block"}}>بینەر: {element.Total_Views}</p>
                                        <p style={{textAlign: "right", display: logo? "none": "block"}}>فرۆشراو: {element.Total_Sold}</p>
                                        <p style={{textAlign: "right", display: logo? "none": "block"}}>بەش:{element.Category}</p>
                                        {element.Size && <p style={{textAlign: "right", display: logo? "none": "block"}}>قەبارە:{element.Size}</p>}
                                        {!orderButton && element.noCondition === 0 && <p style={{textAlign: "right", display: "block"}}>باری کاڵا:{element.Conditions}</p>}
                                        { (quantityAsked || element.total) && <p style={{textAlign: "right", color: "darkorange", fontWeight: "bold", textShadow: "0px 0px 2px white"}}>دانەی داواکراو: {quantityAsked || element.total}</p>}
                                        {Asker && <p dir="rtl" style={{textAlign: "right", display: logo? "none": "block", borderTop: "1px solid black"}}>داواکار:{Asker}</p>}
                                        {AskedQuantity && <p dir="rtl" style={{textAlign: "right", display: logo? "none": "block"}}>ژمارەی کاڵای داواکراو:{AskedQuantity}</p>}
                                        {Post_Id && <input data-for={element.Id} style={{width: "150px"}} type="number" placeholder="Quantity" min="1" step="1" max={element.Quantity} onChange={handleQuantity} maxLength="3" value={quantity} required/>}
                                        {Post_Id &&
                                        <section>
                                            کۆی گشتی:
                                        {quantity >= 0 ? (element.Price * quantity).toLocaleString(): 0}
                                        </section>                                       
                                        }
                                    </section>
                                    {
                                        orderButton && <button className="btn btn-primary mb-4" onClick={()=>{order(element.Id)}}>زانیاری زیاتر</button>
                                    }
                                    {
                                        (addCart && element.Quantity > 0) && <button className="btn btn-primary mb-2" onClick={()=>{AddToCart(element.Id)}}>زیاد کردنی کاڵا</button>
                                    }

                                    {
                                        !orderButton && localStorage.getItem("Username") && element.Phone && 
                                        <section className="d-flex align-items-center justify-content-evenly">
                                            <a className="mb-2" href={element.Username === "Daim shop market" ? "https://wa.me/"+element.ShopperPhone : "https://wa.me/"+element.Phone}><Icon.Whatsapp size={26} color="#25D366"/></a>
                                            <a className="mb-2" href={element.Username === "Daim shop market" ? "tel:"+element.ShopperPhone : "tel:"+element.Phone}><Icon.PhoneVibrate size={26}/></a>
                                            <a className="mb-2" href={element.Username === "Daim shop market" ? `skype:${element.ShopperPhone}?call`:`skype:${element.Phone}?call`}><Icon.Skype size={26} color="#00AFF0"/></a>
                                        </section>
                                    }
                                    {
                                        tax && (
                                            <section>
                                                <section className="form-floating">
                                                    <input placeholder="i" className="form-control" onChange={(e)=>{setTax(e.target.value)}} value={Tax}/>
                                                    <label>Tax</label>
                                                </section>
                                                <button className="btn btn-primary mt-1" onClick={updateTax}>Update Tax</button>
                                            </section>
                                        )
                                    }
                                    {
                                        ReadyToEdit && (
                                            <section>
                                                <button className="btn btn-info mb-2" onClick={()=>{updatePost(element.Id)}}>گۆڕینی زانیاری</button>
                                            </section>
                                        )
                                    }
                                    {
                                        editContent && (
                                            <section>
                                                <button className="btn btn-info mb-2" onClick={()=>{SendUpdate()}}>نوێ کردنەوە</button>          
                                            </section>
                                        )
                                    }
                                    {
                                        preparing && 
                                            <section>
                                                <button className="btn btn-success mb-2" onClick={Prepared} disabled={Status === 'ئامادە نیە'? false:true}>                                     
                                                {
                                                    Status === 'ئامادە نیە'? "کاڵاکان ئامادەن" : "کاڵاکان نێردران بۆ مەندووب"
                                                }
                                                </button>
                                            </section>
                                    }    
                                </section>
                    </section>
                )
        })}
        {
            list.length === 0 &&
            <section>
                !لە ئێستادا هیچ پۆستێك بەردەست نیە
            </section>
        }
        </>
    )
}