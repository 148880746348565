import { useEffect, useState } from "react"
import Server from "./../Server";
import PostDetails from "./PostDetails";
export default function MarketShop(){
    const [detail, setDetail] = useState(null)
    /*const [favorites, setFavorites] = useState([]);*/
    const Username = localStorage.getItem("SelectedShop")
    const [marketHour, setMarketHour] = useState({
      Open : "",
      Close : ""
    })
    useEffect(()=>{
        Server.post("/Post/MarketShopPosts", {Username : Username}).then(res=>{
            setDetail(res.data)
            console.log(res.data)
        }).catch(res=>console.log(res))
    }, [Username])
    useEffect(()=>{
      Server.post("/Manage/GetMarketHourse", {Username : Username}).then(res=>{
        setMarketHour({Open: res.data[0].Opens, Close : res.data[0].Closes});
        console.log(marketHour)
      })
    }, [])
    /*
    const toggleFavorite = (Id) => {
        // Check if the item is already in favorites
        const isFavorite = favorites.includes(Id);
    
        if (isFavorite) {
          // Remove from favorites
          setFavorites(favorites.filter((item) => item !== Id));
          Server.post("/Post/removeFavorite", {Id: Id, Username: localStorage.getItem("Username"), Location: "Marketer"}).then(res=>{console.log(res)}).catch(res=>{console.log(res)})
        } else {
          // Add to favorites
          setFavorites([...favorites, Id]);
          Server.post("/Post/addFavorite", {Id: Id, Username: localStorage.getItem("Username"), Location: "Marketer"}).then(res=>{console.log(res)}).catch(res=>{console.log(res)})
        }
      };
    */
   if(detail !== null){
     return(
      <section>
        {
          marketHour.Open !== null && 
          <section className="row pt-2 pb-2 border mt-2 mb-2 rounded" style={{width:"80vw", margin: "0 auto"}}>
            <h3 className="text-white">Market Hour</h3>
            <section className="col-6 text-white">
              Open: {marketHour.Open}
            </section>
            <section className="col-6 text-white">
              Close: {marketHour.Close}
            </section>
          </section>
        }
         <section style={{display:"grid", gridTemplateColumns: "auto auto"}}>
            <PostDetails list={detail} location="Marketer" orderButton={true} size="small" logo={true}/>
         </section>
      </section>
     )
   }else{
    return(
      <section>
        
      </section>
    )
   }
}