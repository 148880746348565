import { useEffect, useState } from "react";
import Server from "./../Server";
import PostDetails from "./PostDetails";
export default function Favorites(){
    const [detail, setDetail] = useState(null)
    useEffect(()=>{
        const load = async ()=>{
            await Server.post("/Post/getAllFavorites", {For: "Favorites", Username: localStorage.getItem("Username")}).then(res=>{
                setDetail(res.data)
            }).catch(res=>console.log(res))
        }
        load()
    }, [])
    /*
    const toggleFavorite = (Id, Location)=>{
        Server.post("/Post/removeFavorite", {Id: Id, Username: localStorage.getItem("Username"), Location: Location}).then(res=>{
            if(res.data === "Success"){
                window.location.reload()
            }
            }).catch(res=>console.log(res))
    }*/
    return (
        <section>
            <PostDetails list = {detail} location = "Favorites" orderButton={true}/>
        </section>
    )
}