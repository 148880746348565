import * as Icon from "react-bootstrap-icons";
import {useState} from 'react';
import {location} from "./LoadingLocation";

const EnableDarkMode = (trigger, update)=>{
    if(!trigger){
        document.getElementById('sun').style.display = "none";
        document.getElementById('moon').style.display = 'block';
        update(true);
    }else{
        document.getElementById('sun').style.display = "block";
        document.getElementById('moon').style.display = 'none';
        update(false);
    }
}
const Direct = (url)=>{
    window.location = location + url;
}
const Logout = ()=>{
    localStorage.clear();
    Direct("./");
}
export default function Aside(){
    const [darkmode, isdarkmode] = useState(false);
    const active = localStorage.getItem("Username");
    return (
    <aside id='aside' className="d-flex flex-column justify-content-start align-items-center">
        <section className="d-flex justify-content-between align-items-center">
            <section>
                {
                    active ?
                    <Icon.Gear id="gear" onClick={()=>{localStorage.setItem("selectedUsername", active);Direct('ProfileSetting')}}/>
                    :
                    <></>
                }
            </section>
            <section className="d-flex align-items-center justify-content-around" id={active?"username":""}>
                {
                    active ?
                    <section onClick={()=>{window.location="./Profile"}}>
                    <Icon.PersonCircle/>
                    <span className="ms-2">{active}</span>
                    </section>
                    :
                    <></>       
                }
            </section>
            <section>
                <section className="d-none" onClick={()=>{EnableDarkMode(darkmode, isdarkmode)}}>
                    <Icon.Sun id='sun'/>
                    <Icon.Moon id='moon' style={{'display':"none"}}/>
                </section>
            </section>
        </section>
        <section>

        </section>
        <section className="btn btn-primary d-flex justify-content-between align-items-center" onClick={()=>{Direct("Shop")}}>
            <Icon.Shop size={22}/>
            دووکانەکان
        </section>
        <section className="btn btn-primary d-flex justify-content-between align-items-center" onClick={()=>{Direct("Community")}}>
            <Icon.People size={22}/>
            کۆمەڵگە
        </section>
        <section className="btn btn-primary d-none justify-content-between align-items-center" onClick={()=>{Direct("Aboutus")}}>
            دەربارەی ئێمە
        </section>
        <section className="btn btn-primary d-none justify-content-between align-items-center" onClick={()=>{Direct("Contactus")}}>
            پەیوەندیمان پێوەبکە
        </section>
        <section className="btn btn-primary d-flex justify-content-between align-items-center" onClick={()=>{Direct('Feedback')}}>
                <Icon.Postcard size={22}/>
                پەیوەندیمان پێوەبکە
        </section>
        {
            active ?
            <>
            {
                localStorage.getItem("Role") !== "5" &&    
                <section className="btn btn-success d-flex justify-content-between align-items-center" onClick={()=>{Direct('Post')}}>
                    <Icon.FileEarmarkImage size={22}/>
                    پۆست
                </section>
            }
            {
                localStorage.getItem("Role") <= 2 &&
                <section className="btn btn-success d-flex justify-content-between align-items-center" onClick={()=>{Direct('Manage')}}>
                    <Icon.Gear size={22}/>
                    بەڕێوەبردن
                </section>
            }
            {
                localStorage.getItem("Role") === "5" &&
                <section className="btn btn-success d-flex justify-content-between align-items-center" onClick={()=>{Direct('Delivery')}}>
                    <Icon.FilePost size={22}/>
                    گەیاندن
                </section>
            }
            {
                localStorage.getItem("Role") === "4" &&
                <section className="btn btn-success d-flex justify-content-between align-items-center" onClick={()=>{Direct('MarketDetail')}}>
                    <Icon.FilePost size={22}/>
                    داواکاریەکان
                </section>
            }
            <section className="btn btn-success d-flex justify-content-between align-items-center" onClick={()=>{Direct('Promotion')}}>
                <Icon.Arrow90degUp size={22}/>
                پلە بەرز کردنەوە
            </section>
            <section className="btn btn-primary d-flex justify-content-between align-items-center" data-type="logout" onClick={Logout}>
                <Icon.ArrowBarRight size={22}/>
                دەرچوون
            </section> 
            </>
            : 
            <section className="btn btn-primary d-flex justify-content-between align-items-center" onClick={()=>{Direct("Login")}}>
                <Icon.ArrowBarLeft size={22}/>
                داخلبوون
            </section>
        }
    </aside>);
}