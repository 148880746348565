import React, { useEffect, useState } from "react";
import MapComponent from "./MapComponent";
import Server from "./../Server";
import "./../css/ProfileSetting.css"

export default function ProfileSetting() {
  const [detail, setDetail] = useState([]);
  const [mapCenter, setMapCenter] = useState([48.866667, 2.333333]);

  useEffect(() => {
    const askForLocationPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

        if (permissionStatus.state === 'prompt') {
          // Prompt the user for permission
          
        } else if (permissionStatus.state === 'granted') {
          // Permission already granted, you can proceed
        } else {
          // Permission denied
          console.log('Geolocation permission denied by user.');
        }
      } catch (error) {
        // Handle errors
        console.error('Error checking geolocation permission:', error.message);
      }
    };

    askForLocationPermission();
  }, []);



  const [formData, setFormData] = useState({
    FullName: '',
    Phone: '',
    Username : localStorage.getItem("Username"),
    Lat: 0,
    Long: 0,
    ReadyToWork: "No",
    Open: "",
    Close: ""
  });
  useEffect(() => {
    Server.post("/Manage/UserDetail", { username: localStorage.getItem("selectedUsername") })
      .then((res) => {
        setDetail(res.data[0]);
        setFormData({...formData, FullName:res.data[0]?.Name, Phone:res.data[0]?.Phone, Lat: res.data[0]?.Lat})
        if(res.data[0].Lat !== undefined && res.data[0].Long !== undefined){
           setMapCenter([res.data[0].Lat, res.data[0].Long]);
        }else{
          setMapCenter([48.866667, 2.333333]);
        }
       
      })
      .catch((res) => console.error(res));
  }, []);

  const GetLocation = () => {
    navigator.geolocation.getCurrentPosition((e) => {
      const newCoordinates = [e.coords.latitude, e.coords.longitude];
      setFormData((prevState) => ({
        ...prevState,
        Lat: e.coords.latitude,
        Long: e.coords.longitude,
      }));
      setMapCenter(newCoordinates);
     });
  };
  const updateProfile = ()=>{
    if(formData.Lat !== 0 && formData.Long !== 0 && detail.Role !== 4){
      Server.post("/Manage/UpdateProfile", formData).then(res=>console.log(res.data)).catch(res=>console.log(res))
    }else{
      Server.post("/Manage/UpdateMarketProfile", formData).then(res=>console.log(res.data)).catch(res=>console.log(res))
    }
  }
  
  const handleChange = (e)=>{
    const {name, value} = e.target;
    setFormData({...formData, [name]:value})
}
const ReadyToWorkStatus = async (e)=>{
  let value = e.target.value
  await Server.post("/Manage/UpdateWorkStatus", {Username: localStorage.getItem("Username"), Value: value}).then(res=>console.log(res.data))
}
const covertReadyToWork = (current)=>{
  return current === "No" ? "Yes" : "No";
}
return (
    <>
      <section className="table-responsive-sm">
              <table className="table table-hover">
                <thead></thead>
                <tbody>
                        <tr style={{ textAlign: "left" }}>
                          <th>Name</th>
                          <td>
                            <input
                              type="text"
                              onChange={handleChange}   
                              value={formData.FullName}                 
                              name="FullName"
                            />
                          </td>
                        </tr>   
                        <tr style={{ textAlign: "left"}}>
                          <th>Username</th>
                          <td><input type="text" value={detail.Username} readOnly={true}/></td>
                        </tr>
                        <tr style={{ textAlign: "left"}}>
                          <th>Email</th>
                          <td><input type="text" value={detail.Email} readOnly={true}/></td>
                        </tr>
                        <tr style={{ textAlign: "left"}}>
                          <th>Phone</th>
                          <td><input type="tel" onChange={handleChange} value={formData.Phone} name="Phone"/></td>
                        </tr>
                        <tr style={{ textAlign: "left"}}>
                          <th>Date of Birth</th>
                          <td><input type="date" value={detail.Dob} readOnly={true}/></td>
                        </tr>
                        <tr style={{ textAlign: "left"}}>
                          <th>Role</th>
                          <td><input type="text" value={detail.Role} readOnly={true}/></td>
                        </tr>
                        {
                          detail.Role === 5 && <tr style={{ textAlign: "left"}}>
                            <th>Ready To Work</th>
                            <td>
                              <select className="form-control ps-4"
                              onChange={ReadyToWorkStatus}
                              >
                              <option
                              value={
                                detail.ReadyToWork
                              }
                              >
                                {
                                  detail.ReadyToWork
                                }
                               
                              </option>
                              <option
                              value = {
                                detail.ReadyToWork === "No" ? "Yes" : "No"
                              }
                              >
                                {
                                  detail.ReadyToWork === "No" ? "Yes" : "No"
                                }
                              </option>
                              </select>

                            </td>
                          </tr>
                        }
                        {
                          detail.Role === 4 && <tr style={{textAlign:"left"}}>
                            <th>Open Hour</th>
                            <td>
                              <input name="Open" type="time" value={detail.Open} onChange={handleChange}/>
                            </td>
                          </tr>
                        }
                        {
                          detail.Role === 4 && <tr style={{textAlign:"left"}}>
                            <th>Close Hour</th>
                            <td>
                              <input name="Close" type="time" value={detail.Close} onChange={handleChange}/>
                            </td>
                          </tr>
                        }
                        
                </tbody>
              </table>
      </section>
      <button className="btn btn-primary mb-3 mt-3" onClick={GetLocation}>
        Get Location
      </button>
      <button className="btn btn-primary ms-2" onClick={updateProfile}>Update Your Profile</button>
      <section style={{width:"80%", margin:"0 auto", overflow:"hidden", height:"300px"}}>
        <MapComponent center = {mapCenter}/>
      </section>
    </>
  );
}
