import "./../css/Registration.css";
import React, { useState } from 'react';
import Server from "./../Server";

export default function Signup() {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password:'',
    confirmPassword:'',
    referal : '',
    gender  : 'Male',
    dob     : '',
    profile : '',
    Phone   : ''
  });

  const [error, setError] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    
    if(formData.password.length < 8){
      setError("Your password must be at least 8 characters!");
      return;
    }
    if(formData.password !== formData.confirmPassword){
      setError("The password does not match confirm password!");
      return;
    }
    const checkUsernameExists = await Server.post("/Profile/UsernameExists", formData);

    if(checkUsernameExists.data[0] !== undefined){
      setError("Username already taken.")
      return;
    }
    
    const checkEmailExists = await Server.post("/Profile/EmailExists", formData);
    if(checkEmailExists.data[0] !== undefined){
      setError("Email already taken.")
      return;
    }
    let random = Math.floor(Math.random() * 2) + 1
    formData.profile = formData.gender + random + ".jpg";

    const signup = await Server.post("/Profile/Signup", formData);
    if(signup.data === "Accepted"){
      localStorage.setItem('Username', formData.username);
      localStorage.setItem('Email', formData.email);
      localStorage.setItem('Role', 6);
      window.location="./";
    }


    // Add authentication logic here
    // Typically, you would make an API request to verify credentials

    // If authentication is successful, redirect the user
    setError("");
  };

  return (
    <section>
      <h2>Signup</h2>
      <p style={{color:"red"}}>{error}</p>
      <form onSubmit={handleSignup} className="form-control">
      <section className="form-floating">
          <input
            type="text"
            name='username'
            value={formData.username}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Username:</label>
        </section>
        <section className="form-floating">
          <input
            type="email"
            name='email'
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Email:</label>
        </section>
        <section className="form-floating">
          <input
            type="password"
            name='password'
            value={formData.password}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Password:</label>
        </section>
        <section className="form-floating">
          <input
            type="password"
            name='confirmPassword'
            value={formData.confirmPassword}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Confirm Password:</label>
        </section>
        <section className="form-floating">
          <input
            type="text"
            name='Phone'
            value={formData.Phone}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Phone:</label>
        </section>
        <section className="form-floating">
          <select className="form-control" name="gender" onChange={handleChange}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <label>Gender:</label>
        </section>
        <section className="form-floating">
        <input
            type="date"
            name='dob'
            value={formData.dob}
            onChange={handleChange}
            className="form-control"
            required
            min="1907-01-01" 
            max="2030-12-31"
            style={{textAlign: "center"}}
            dir="rtl"
            />
            <label>Date of Birth:</label>
        </section>
        <section className="form-floating">
          <input
            type="text"
            name='referal'
            value={formData.referal}
            onChange={handleChange}
            className="form-control"
            placeholder="i"           
            />
            <label>Referal (optional):</label>
        </section>
        <button className="btn btn-primary mt-2" type="submit">Signup</button>
        <p>Already have an account? <a href='Login'>Login</a></p>
      </form>
    </section>
  );
}
