import { useEffect, useState } from "react"
import Server from "./../Server";
import PostDetails from "./PostDetails";
export default function Search(){
    const searched = localStorage.getItem("search")
    const [deliveries, setDeliveries] = useState([]);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [buttonActive, setButtonActive] = useState(false)
    const [data, setData] = useState([])
    useEffect(()=>{
        if(searched === "deliveries" && localStorage.getItem("Role") === "4"){
            Server.get("/Manage/getDeliveries").then(res=>setDeliveries(res.data))
        }else{
            Server.post("/Search/search", {Search: searched}).then(res=>{
                setData(res.data)
            })
        }
    }, [searched])
    const updatingEmail = async (username)=>{
        await new Promise(response=>{
            Server.post("/Manage/getEmail", {Username: username}).then(res=>{
                if(res.data){
                    setEmail(res.data.Email);
                    setUsername(username);
                    response();
                }
            })
        })
    }
    useEffect(()=>{
        if(email.length > 0){
            let currentUser = localStorage.getItem("Username");
            Server.post("/Manage/addedAssigned", {Marketer: currentUser, Delivery: username}).then(res=>{
                if(res.data === "inserted"){                   
                    const data = {
                        To : email,
                        Subject : "داواکراویت بۆ کاری گەیاندن",
                        HTMLContent : `
                        <section style='text-align:right'>
                        <h2>${username} سڵاو</h2>
                        <p> ${currentUser}  هەڵبژێردراوی بۆ کاری گەیاندن لەلایەن </p>             
                        <a href="https://daimshop.future-dev.online/Confirm?Delivery=${username}&Marketer=${currentUser}">بەژداربە</a>
                        </section>
                        `
                    }
                    Server.post("/Email/send-email", data).then(res=>{console.log(res.data)});
                    setButtonActive(true);
                }
            });
        }
    },[email, username])
    return(
        <>
        {
            (searched.toLocaleLowerCase() === "deliveries" && localStorage.getItem("Role") === "4") && 
            <table style={{margin: "0 auto"}} className="table">
                <thead>
                    <tr>
                        <th>Profile</th>
                        <th>Username</th>
                        <th>City</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>   
            {
            (deliveries != null && deliveries.length > 0) && deliveries.map((val, key)=>{
                let profile  = `https://future-dev.tech/server/ProfileImage/${val.Username}/profile.jpg`;
                return(
                    <tr key={key} style={{width:"80dvw"}}>
                        <td><img src={profile} alt="profile" style={{width: "50px", aspectRatio: "1/1", objectFit: "contain"}}/></td>
                        <td>{val.Username}</td>
                        <td>{val.City}</td>
                        <td><button disabled={buttonActive} className={buttonActive ? 'btn btn-danger' : 'btn btn-info'} onClick={()=>{updatingEmail(val.Username)}}>Add</button></td>
                    </tr>
                )
            })}
            </tbody>
            </table>
        }
        {
            searched.toLocaleLowerCase() !== "deliveries" && data.length > 0 && 
                <PostDetails list={data} orderButton={true} location="Search"/>
        }
        </>
    )
}