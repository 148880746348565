import { useEffect, useState } from "react"
import Users from "./Functions/Users";
import Posts from "./Functions/Posts";
import Promotions from "./Functions/Promotions";
import Tax from "./Functions/Tax";
import Feedback from "./Functions/Feedback";
import Deliveries from "./Functions/Deliveries";
import OtherDeliveries from "./Functions/OtherDeliveries";
export default function Admin(){
    const [selectedPage, setSelectedPage] = useState(<Users/>);
    useEffect(()=>{
        switch(sessionStorage.getItem("SelectedPage")){
            case "Users":
                setSelectedPage(<Users/>)
                break;
            case "Posts":
                setSelectedPage(<Posts/>)
                break;
            case "Promotion":
                setSelectedPage(<Promotions/>)
                break;
            case "Tax":
                if(localStorage.getItem("Role") === "1"){
                    setSelectedPage(<Tax/>)
                }
                break;
            case "Feedback":
                if(localStorage.getItem("Feedback") === "1"){
                    setSelectedPage(<Feedback/>)
                }
                break;
            case "Deliveries":
                if(localStorage.getItem("Deliveries") === "1"){
                    setSelectedPage(<Deliveries/>)
                }
                break;
            case "DeliveriesOthers":
                    if(localStorage.getItem("DeliveriesOthers") === "1"){
                        setSelectedPage(<Deliveries/>)
                    }
                    break;
            default:
                setSelectedPage("You do not have access")
        }
    }, [])
    const changePage = (e)=>{
        switch(e.target.name){
            case "Users":
                setSelectedPage(<Users/>)
                sessionStorage.setItem("SelectedPage", "Users");
                break;
            case "Posts":
                setSelectedPage(<Posts/>)
                sessionStorage.setItem("SelectedPage", "Posts");
                break;
            case "Promotion":
                setSelectedPage(<Promotions/>)
                sessionStorage.setItem("SelectedPage", "Promotion");
                break;
            case "Tax":
                if(localStorage.getItem("Role") === "1"){
                    setSelectedPage(<Tax/>)
                    sessionStorage.setItem("SelectedPage", "Tax");
                }
                break;
            case "Feedback":
                if(localStorage.getItem("Role") === "1"){
                    setSelectedPage(<Feedback/>)
                    sessionStorage.setItem("SelectedPage", "Feedback");
                }
                break;
            case "Deliveries":
                if(localStorage.getItem("Role") === "1"){
                    setSelectedPage(<Deliveries/>)
                    sessionStorage.setItem("SelectedPage", "Deliveries");
                }
                break; 
            case "DeliveriesOthers":
                    if(localStorage.getItem("Role") === "1"){
                        setSelectedPage(<OtherDeliveries/>)
                        sessionStorage.setItem("SelectedPage", "DeliveriesOthers");
                    }
                    break; 
            default:
                setSelectedPage("You do not have access")
        }
    }
    return(
        <section className="mt-2">
        <button className="btn btn-primary" onClick={changePage} name='Users'>Users</button>
        <button className="btn btn-primary ms-1" onClick={changePage} name='Posts'>Posts</button>
        <button className="btn btn-primary ms-1" onClick={changePage} name='Promotion'>Promotions</button>
        <button className="btn btn-primary ms-1" onClick={changePage} name='Tax'>Tax</button>
        <button className="btn btn-primary ms-1" onClick={changePage} name='Feedback'>Feedback</button>
        <button className="btn btn-primary ms-1" onClick={changePage} name='Deliveries'>Deliveries</button>
        <button className="btn btn-primary ms-1" onClick={changePage} name='DeliveriesOthers'>Other Deliveries</button>
       <br/>
        {selectedPage}
        </section>
    )
}