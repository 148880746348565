import "./../App.css";
import * as Icon from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import Server from "./../Server";
import {location} from "./LoadingLocation";
import "./../css/Footer.css";


const ToggleMenu = (trigger, update)=>{
   if(!trigger){
       document.getElementById("menu").style.display = "none";
       document.getElementById("X").style.display = "block";
       document.getElementById("aside").style.width = "100vw";
       update(true);
   }else{
       document.getElementById("menu").style.display = "block";
       document.getElementById("X").style.display = "none";
       document.getElementById("aside").style.width = "0vw";
       update(false);
   }
}
export default function Footer(){
    const [MenuTriggered, isMenuTriggered] = useState(false)
    const [points, setPoints] = useState(0)
    const [askPrePaid, setAskPrePaid] = useState(false);
    const [agreement, setAgreement] = useState(false);

    const currentRole = localStorage.getItem("Role");

    useEffect(()=>{
        let username = localStorage.getItem("Username") || null         
        const load = async ()=>{
            Server.post("/Profile/getPoints", {Username: username})
            .then(res=>{setPoints(res.data[0]?.Point)
            if(res.data[0]?.Point === 0 && localStorage.getItem("Role") === "4"){
               setAskPrePaid(true)
            }
            })
            .catch(res=>console.log(res))
            
        }
        load();
        Server.post("/Profile/Agreement", {Username: localStorage.getItem("Username")}).then(
            res=>{
                if(res.data === "Has not accepted"){
                    setAgreement(true)
                }
            }
        )
        const role = Server.post("/Profile/getRole", {Username: username}).then(res=>{
            if (currentRole !== res.data[0].Role){
               // localStorage.clear();
               // window.location.reload();
            }
        })
    }, [])
    const updateAgreement = ()=>{
        Server.post("/Profile/Agreed", {Username: localStorage.getItem("Username")}).then(
            res=>{
                if(res.data === "updated"){
                    window.location.reload();
                }
            }
        )
    }
    return (
    <>
    {
        localStorage.getItem("Username") && agreement && 
        <section className="Agreement">
            <section className="position-relative">
                <section dir="rtl" style={{borderBottom: "1px solid black", padding: "1em"}}>سڵاوت لێ بێ: {localStorage.getItem("Username")}</section>
                <section dir="rtl" style={{textAlign: "right", padding:"1em", paddingRight: "0"}}>بۆ بەکارهێنانی خزمەت گووزاری لەلایەن Daim Shopەوە ئەوا پێویست ئەکا کلیک لەسەر دوگمەی "بەڵێ، ڕازیم" دابگری. </section>
            
                <section>
                    <section style={{borderBottom:"1px solid black", width:"max-content", margin: "0 auto"}}>
                    رازیم بەو خالانەی خوارەوە
                    </section>
                    <ol dir="rtl" className="mt-2">
                        <li>زانیاری سەبارەت بە من ({localStorage.getItem("Username")}) کۆبکرێتەوە وەکوو، ناو، ناویبەکارهێنەر، ڕۆژی لە دایک بوون، ژمارەی تەلەفۆن</li>
                        <li>زانیاری سەبارەت بە شوێنی ئامێری بەکارهێنانەکەم کۆ بکرێتەوە، بە مەبەستی گەیاندن بەبێ کێشە</li>
                        <li>هەرکاتێ ویستم ئەوا ئەتوانم ناوی بەکارهێنەرەکەم بسڕمەوە بە پەیوەندی کردن بە بەشی کارگێڕ</li>
                    </ol>
                </section>
                <section className="mb-2">
                    <b>تێبینی: بەبێ ڕەزامەندی تۆ لەو بەشانەی سەرەوە، ئەوا ناتوانی خزمەت گووزاریەکانمان بەکار بێنیت</b>
                </section>
                <section className="mb-2">
                    <span><button className="btn btn-success me-1" onClick={updateAgreement}>بەڵێ، ڕازیم</button></span>
                    <span><button className="btn btn-danger" onClick={()=>{setAgreement(false)}}>نەخێر، ڕازی نیم</button></span>
                </section>
            </section>
        </section>
    }
    {
        askPrePaid &&
    <section className="AskForPrePaid">
        <section className="position-relative">
            <section style={{position: "absolute", top: "10px", right: "10px"}} onClick={()=>{setAskPrePaid(false)}}><Icon.XCircle size={22} color="red"/></section>
            <section dir="rtl" className="mt-2">سڵاو: {localStorage.getItem("Username")}</section>
            <hr/>
            <section className="p-4 pt-0" style={{textAlign: "right"}} dir="rtl">
                باڵانسە کەت بریتیە لە
                <u> 0DS </u>
                تکایە باڵانسەکەت پڕ بکەرەوە بە پەیوەندی کردن بە
                <br/>
                <a className="mb-2" href="https://wa.me/9647736999576">773-699-9576</a>
            </section>
        </section>
    </section>
    }
    <footer className="d-flex text-white justify-content-around align-items-center">
        <section style={{display: "none"}}>
            {points || 0} DS
        </section>
        <section onClick={()=>{window.location = location;}}>
            <Icon.HouseFill/>
        </section>
        <section id='menuholder' onClick={()=>{ToggleMenu(MenuTriggered, isMenuTriggered)}}>     
            <Icon.List id='menu' />
            <Icon.X id='X'/>
        </section>
    </footer>
    </>
    );
}