import './App.css';
import Markets from "./Pages/Markets";
import Stories from './Pages/Stories';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useEffect, useState } from 'react';
import Server from "./Server";
import PostDetails from './Pages/PostDetails';
import ads from "./Images/Daim Shop Ads.png"

function App() {
  const [mostViews, setMostViews] = useState([]);
  const [mostSells, setMostSells] = useState([]);
  useEffect(() => {
    const loadMostViewsPost = async ()=>{
      await Server.get("/Post/getMostViews").then(res=>{
        setMostViews(res.data);
      })
    }
    loadMostViewsPost();
    const loadMostSellsPost = async ()=>{
      await Server.get("/Post/getMostSold").then(res=>{
        setMostSells(res.data);
      })
    }
    loadMostSellsPost();
    setTimeout(()=>{
      let seenInfo = sessionStorage.getItem("seenInfo");
    if (!seenInfo) {
      Swal.fire({
        title: "بەخێربێیت بۆ بازاڕی دایم",
        text: "لێرە ئەتوانی کاڵاکانت بۆ فرۆشتن دانەیت",
        icon: "success",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      }).then(() => {
        // Set the local storage item after the alert is dismissed
        sessionStorage.setItem("seenInfo", "true");
      });
    }
    }, 1500)
    
  }, []);
  return (
    <section className="App">
      <img src={ads} alt="ads" style={{width:"100%", objectFit:"cover", padding:"1em", borderRadius:"15px"}}/>
      <Stories/>
      <h4 className='text-black bg-warning pt-3 pb-3'>زۆرترین بینەر</h4>
      <section className='d-flex overflow-x-scroll pt-3 pb-3'>
        <PostDetails location="Marketer" list={mostViews} orderButton={true} size="small" logo={true}/>
      </section>
      <h4 className='text-black bg-warning pt-3 pb-3'>زۆرترین فرۆش</h4>
      <section className='d-flex overflow-x-scroll pt-3 pb-3'>
        <PostDetails list={mostSells} orderButton={true} size="small" logo={true}/>
      </section>
      <Markets title='فرۆشگاکان' Data='Markets'/>
      <Markets title='فرۆشگای گشتی' Data='Community'/>
    </section>
  );
}

export default App;
