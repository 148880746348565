import { useEffect, useState } from "react"
import Server from "./../../../Server"
export default function Feedback(){
    const [detail, setDetail] = useState([])
    useEffect(()=>{
        Server.post("/Feedback/getFeedbacks").then(
            res=>{
                setDetail(res.data)
            }
        )
    }, [])
    return(
        <section className="table-responsive-sm">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        detail.length > 0 && detail.map((elem, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{elem.Id}</td>
                                    <td>{elem.title}</td>
                                    <td>{elem.description}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </section>
    )
}