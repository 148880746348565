import "./../css/Markets.css";
import { useEffect, useState} from "react";
import Server from "./../Server";
import PostDetails from "./PostDetails";
import loader from "./../Images/loading/loading__.gif"
export default function Markets(props){
   const [info, setInfo] = useState([])
   const [daimshopPosts, setDaimshopPosts] = useState([])
   const [displayedPosts, setDisplayedPosts] = useState([]);
   const [activeFilter, setActiveFilter] = useState(null);
   const [daimShopLoader, setDaimShopLoader] = useState(true);


   //const [favorites, setFavorites] = useState([]);
   useEffect(()=>{
    if(props.Data === "Community"){
        Server.post("/Post/getPosts", {Type : "Community"}).then(res=>{
            const data = res.data;
            setInfo(data);
        }).catch(res=>console.log(res))
        /*
        Server.post("/Post/getAllFavorites", {For: "Community", Username: localStorage.getItem("Username")}).then(res=>{
            res.data?.map((val)=>{
                setFavorites([val.PostId]) 
            })
        })
        */
    }else if(props.Data === "Markets"){
        Server.post("/Post/getPosts", {Type : "Market"}).then(res=>{
            setInfo([res.data])
        }).catch(res=>console.log(res))
        Server.get("/Post/DaimShop").then(
            res=>{
                setDaimshopPosts(res.data);
                setDisplayedPosts(res.data);
                setDaimShopLoader(false)
            }
        )
    }
   }, [props.Data])
   const Direction = (Username)=>{
    localStorage.setItem("SelectedShop", Username)
    window.location = "/MarketShop";
    }

    /*
    const toggleFavorite = (Id) => {
        // Check if the item is already in favorites
        const isFavorite = favorites.includes(Id);
        
    if (isFavorite) {  
      // Remove from favorites
      setFavorites(favorites.filter((item) => item !== Id));
      Server.post("/Post/removeFavorite", {Id: Id, Username: localStorage.getItem("Username"), Location: "Community"}).then(res=>{console.log(res)}).catch(res=>{console.log(res)})
    } else {
      // Add to favorites
      setFavorites([...favorites, Id]);
      Server.post("/Post/addFavorite", {Id: Id, Username: localStorage.getItem("Username"), Location: "Community"}).then(res=>{console.log(res)}).catch(res=>{console.log(res)})
    }
};
*/
const uniqueCategories = Array.from(new Set(daimshopPosts.map(item => item.Category)));
const updatePosts = (filter)=>{
    // Update daimshopPosts where category == filter
    if (filter === activeFilter) {
        // If the filter is already active, reset to show all posts and clear the active filter
        setDisplayedPosts(daimshopPosts);
        setActiveFilter(null);
    } else {
        // If it's a different filter, update the displayed posts and set the active filter
        const filteredPosts = daimshopPosts.filter(post => post.Category === filter);
        setDisplayedPosts(filteredPosts);
        setActiveFilter(filter);
    }
}    
return (
        <section className="mainMarkets">
            {
                props.Data === "Markets" &&
                    <section>
                        <h4 className="text-black bg-warning pt-3 pb-3">Daim shop</h4>
                        <section className="filter mb-3">
                        {
                            uniqueCategories.length > 0 && uniqueCategories.map((val, index)=>{
                                let buttonStyle = val === activeFilter ? "activeFilterButton" : "filterButton";
                                return (
                                    <section key={index} className={buttonStyle} onClick={()=>{updatePosts(val)}}>
                                        {val}
                                    </section>
                                )
                            })
                        }
                        </section>
                        <section className="daimshopPosts">
                        {
                            displayedPosts.length > 0 && displayedPosts.map((elem, index)=>{
                                    return (
                                        <section>
                                            <PostDetails list={[elem]} location="Marketer" orderButton={true} size="small" logo={true} type="daimshop"/>
                                        </section>
                                                    
                                        )
                            })
                        }
                        {
                            daimShopLoader && 
                                <section>
                                    <img src={loader} alt="Loading" style={{width: "100dvw", margin: "0 auto", objectFit: "fill"}}/>
                                </section>
                        }
                        {
                            displayedPosts.length === 0 &&
                            <section style={{textAlign: "center", width: "100%"}} className="fromRightToLeft">
                            !لە ئێستادا هیچ پۆستێك بەردەست نیە

                            تکایە دواتر سەردانی ئەم بەشە بکەرەوە

                            </section>
                        }
                        </section>
                    </section>        
            }
            <h4 className="fromRightToLeft mt-4 text-black bg-warning pt-3 pb-3">{props.title}</h4>
            {
                props.Data === "Markets" && info?.map((val, key)=>{
                    if(val.length > 0){
                        return(
                            <section key = {key} className="MarketLogos">
                                {
                                    val.map((element, index)=>{
                                        const LogoImg = `https://future-dev.tech/server/Promotions/${element?.Username}/${element?.Logo}`;
                                        return(
                                            <section dir="rtl" key={index} onClick={()=>{Direction(element.Username)}} >
                                                <img src={LogoImg} alt="Logo"/>
                                                <p>{element.Title}</p>
                                                <p>شار: {element.City}</p>
                                            </section>
                                        )
                                    })
                                }
                            </section>
                        )

                    }else{
                        return(
                        <section className="fromRightToLeft">
                            !لە ئێستادا هیچ پۆستێك بەردەست نیە
                            <br/>
                            تکایە دواتر سەردانی ئەم بەشە بکەرەوە
                            
                        </section>
                        )
                    }
                })
            }
            {props.Data === "Community" && <PostDetails list={info} location="Community" orderButton={true} logo={true}/>}
        </section>
    )
}