import { useEffect, useState } from "react"
import Server from "./../../../Server"
import * as Icon from "react-bootstrap-icons"
export default function UserDetail(){
    const [detail, setDetail] = useState([]);
    const [point, setPoint] = useState([]);
    let selectedUser = localStorage.getItem("selectedUsername")
    useEffect(()=>{
        Server.post("/Manage/UserDetail", {username: selectedUser})
        .then(res=>{
            const data = res.data;
            data[0].Point = Number(data[0].Point).toLocaleString()
            setDetail(data)
        })
        .catch(res=>console.error(res))
    }, [selectedUser])

    const UpdateRole = (Ids)=>{
        const updating = (role) =>{
            Server.post("/Manage/UpdateRole", {Id: Ids, Role: role})
            .then(res=>{
                if(res.data.affectedRows === 0){
                    alert("Something is wrong...")
                }else{
                    window.location.reload()
                }
            })
            .catch(res=>console.error(res))
        }
        let prompt = window.prompt("Enter a role: \n1:Owner\n2:Admin\n3:Business man\n4:Marketer\n5:Delivery\n6:Regular")
        switch(prompt){
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
                if(localStorage.getItem("Role") > prompt){
                    alert("Do not have this privilage.");
                    return;
                }
                const confirm = window.confirm("Are you sure to prompt this user to " + prompt + " role?")
                if(confirm === true){
                    updating(prompt)

                }
                break;
            default:
                alert("Wrong Role")
        }
    }
    const Deleting = (Ids)=>{
        const confirm = window.confirm("Are you sure")
        if(confirm === true){
            Server.post("/Manage/Delete", {Id: Ids}, {timeout: 30000})
            .then(res=>{
                if(res.data.affectedRows === 0){
                    alert("Something is wrong...")
                }else{
                    window.location = "./../Manage"
                }
            })
            .catch(res=>console.error(res))
        }
       
    }
    const handleChange = (e)=>{
        const { value } = e.target;
        setPoint(value);
    }
    const updatePoint = async (operator)=>{
        await new Promise(resolve=>{
            Server.post("/Manage/updatePoint", {Username: selectedUser, Point: point, Operator: operator}).then(
                res=>{
                    if(res.data === "update"){
                        window.location.reload()
                    }
                }
            )
            resolve()
        })
    }
    return (
        <section className="table-responsive-sm">
            <button className="btn btn-primary mt-1" onClick={()=>{window.location="./../Manage"}}>Go back</button>
        {
            detail.length > 0 && detail?.map((val, index)=>{
                return(
                    <table key={index} className="table table-striped table-hover table-bordered">
                        <thead>

                        </thead>
                        <tbody>
                        {
                            Object.entries(val).map(([key, value])=>{
                                return(
                                <tr key={key}>
                                    <th>{key}</th>
                                    <td>{value || "N/A"}</td>
                                </tr>
                                )
                            })
                        }
                            <tr>
                                <th>Actions</th>
                                <td>
                                    <section>
                                        <button className="btn btn-danger" onClick={()=>Deleting(val.Id)} disabled={localStorage.getItem("Username") === val.Username ? "Disabled":""}>Delete</button>
                                        <button className="btn btn-primary ms-1" onClick={()=>{UpdateRole(val.Id)}} disabled={localStorage.getItem("Username") === val.Username ? "Disabled":""}>Update Role</button>
                                    </section>
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>
                )
            })
        }
        {
            detail &&
        <section className="d-flex p-4">
            <input className="form-control" placeholder="Points..." value={point} onChange={handleChange}/>
            <button className="btn btn-info ms-1" onClick={()=>{updatePoint("+")}}><Icon.PlusCircle/></button>
            <button className="btn btn-danger ms-1" onClick={()=>{updatePoint("-")}}><Icon.PatchMinus/></button>
        </section>
        }
        </section>
    )
}