import { useState } from "react"
import Server from "./../Server";
import Swal from "sweetalert2";
export default function Feedback(){
    Swal.fire({
        icon: "info",
        title: "لێرەدا ئەتوانیت هەر داواکاریەکت هەبێ بیگەیێنیت بە کارمەندانی دایم بازاڕ",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        },
        showCancelButton: false,
        showConfirmationButton: true,
        confirmButtonText: `ئامادەم`
      })
    const [form, setForm] = useState({
        title: '',
        desc: ''
    })
    const [success, setSuccess] = useState(false)
    const update = ()=>{
        if(form.title.length > 0 && form.desc.length > 0){
            Server.post("/Feedback/Inserting", form).then(
                res=>{
                    console.log(res.data)
                    setForm({title: "", desc: ""})
                    setSuccess(true)
                }
            )
        }
    }
    const handleChange = (e)=>{
        const { name, value } = e.target;
        setForm({...form, [name]: value})
    }
    return(
        <section>
            <section className="form-floating">
                <input className="form-control" placeholder="i" value={form.title} onChange={handleChange} name="title"/>
                <label>
                    ناوونیشان
                </label>
            </section>
            <section className="form-floating">
                <textarea style={{height: "max-content", paddingTop: "3em", paddingBottom:"2em"}} className="form-control" placeholder="i" value={form.desc} onChange={handleChange} name="desc"></textarea>
                <label>
                    زانیاری
                </label>
            </section>
            <button className="btn btn-primary mt-1" onClick={update}>ناردنی زانیاری</button>
        {
            success && (
                <section className="text-success">
                    !!سووپاس بۆ زانیاریەکەت
                    <br/>
                    بەمزووانە پێداچوونەوەئەکەین لەسەر داواکاریەکەت
                </section>
            )
        }
        </section>
    )
}