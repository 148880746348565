import Admin from './Manager/Admin';
export default function Manage(props){
    let Role = props.Role; 
    switch(Role){
        case "1":
            return <Admin/>
        case "2":
            return (<Admin/>)
        default:
            return (<>Access Prohibited!!</>)
        }
}