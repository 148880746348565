import { useEffect, useState } from "react";
import PostDetails from "./PostDetails";
import Server from "./../Server";
export default function ReadyToUpdate(){
    const id = sessionStorage.getItem("ReadyToUpdate");
    const [detail, setDetail] = useState([]);
    useEffect(()=>{
        Server.post("/Post/SinglePost", {Id: id, Location: "Search", Username: localStorage.getItem("Username")}).then(
            res=>{
                setDetail(res.data)
            }
        )
    }, [id])
    return(
        <PostDetails list={detail} editContent={true}/>
    )
}