import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import L from "leaflet"; // Import the Icon from leaflet
import "leaflet/dist/leaflet.css";
import "./../App.css";
import Logo from "./../Images/Markers/map-marker.png";

export default function MapComponent({ center }) {
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    setMapKey((prevKey) => prevKey + 1);
  }, [center]);

  const customIcon = new L.Icon({ // Correct the typo in "Icon"
    iconUrl: Logo,
    iconSize: [32, 32]
  });

  return (
    <MapContainer key={mapKey} center={center} zoom={16}>
      <TileLayer
        attribution=""
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={center} icon={customIcon}></Marker>
    </MapContainer>
  );
}
