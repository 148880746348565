import "./../css/Stories.css";
import { useEffect, useState } from "react";
import Server from "../Server";
import * as Icon from "react-bootstrap-icons";
import Carousel from 'react-bootstrap/Carousel';
import loading from "./../Images/loading/loading__.gif";

export default function Stories(){
    /*
    const timeConverter = (val)=>{

        let UTCDate  = val.PublishedDate
                        let utcYear  = parseInt(UTCDate[0] + UTCDate[1] + UTCDate[2] + UTCDate[3])
                        let utcMonth = parseInt(UTCDate[5] + UTCDate[6])
                        let utcDay   = parseInt(UTCDate[8] + UTCDate[9])
                        let utcHour  = parseInt(UTCDate[11] + UTCDate[12])
                        let utcMinute= parseInt(UTCDate[14] + UTCDate[15])
                        let utcSeconds=parseInt(UTCDate[17] + UTCDate[18])
                        let fixedTime = new Date(Date.UTC(utcYear, utcMonth - 1, utcDay,utcHour, utcMinute, utcSeconds))
                        let date = new Date()
                        let currentTime = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
                        let difference =   currentTime - fixedTime
                        const hoursDifference = Math.floor(difference / (1000 * 60 * 60));
                        const minutesDifference = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                        const secondsDifference = Math.floor((difference % (1000 * 60)) / 1000);

                        let dateResult = "";

                        if(hoursDifference === 0 &&  minutesDifference === 0){
                            dateResult = Math.abs(secondsDifference) + "s ago";
                        }else if(hoursDifference === 0 && minutesDifference > 0){
                            dateResult = Math.abs(minutesDifference) + "m ago";
                        }else if(hoursDifference > 0){
                            dateResult = Math.abs(hoursDifference) + "h ago";
                        }

    }
*/

    const [stories, setStories] = useState([])
    const active = localStorage.getItem("Username") || null
    const [isLoading, setIsLoading] = useState("none");
    const [storyFullScreen, SetStoryFullScreen] = useState(null)
    useEffect(()=>{
        const load = async ()=>{
            const loaded = await Server.get("./stories/getStories")
            if(loaded.data.Username !== null){
                setStories(loaded.data)
            }
        }
        load()
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement) {
              // User exited full-screen mode
              const videos = document.querySelectorAll('VIDEO');
              videos.forEach((elem)=>{
                elem.currentTime = 0;
                elem.pause();
              })
            }
          };   
          document.addEventListener('fullscreenchange', handleFullScreenChange);
          return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
          };
    }, [])
    let distinct = [];
    try{
        distinct = Array.from(new Set(stories.map(s=>s.Username))).map(Username => {
            return {
                Username: Username,
                Length: stories.filter(array=> array.Username === Username).length
            }
        })
    }catch(e){
        console.log(e.message)
    }
  // console.clear()
    const HandleStoryAdd = async (e) =>{
        setIsLoading("block");
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("username", active)
        console.log(e.target.files[0])
        const api = await Server.post("/stories/addStory", formData);
        if(api.data === "success"){
            setTimeout(()=>{
                window.location.reload()

            }, 1500)
        }
    }
    /*
    const increaseView = async (id) => {
        await Server.post("/stories/addView", { Id: id });
    };
    */
    /*
    const EnterFullScreen = (elem, id)=>{
        if (elem.target) {
            if (elem.target.requestFullscreen) {
              elem.target.requestFullscreen();
            } else if (elem.target.mozRequestFullScreen) {
              elem.target.mozRequestFullScreen();
            } else if (elem.target.webkitRequestFullscreen) {
              elem.target.webkitRequestFullscreen();
            } else if (elem.target.msRequestFullscreen) {
              elem.target.msRequestFullscreen();
            }
          }
        increaseView(id)
        elem.target.play();
    }*/
    const openStory = (e) =>{
        let target = e.target;
        let story  = document.getElementById(target.getAttribute("data-for"));
        if(target.getAttribute("data-type") === "video"){
            if(story.classList.contains("showFullStory")){
            }else{
                story.classList.add("showFullStory");
            }
            SetStoryFullScreen(null)
            target.play();
        }
    }
    const changeSlide = ()=>{

    }
    const stopVideos = ()=>{
        document.querySelectorAll("VIDEO").forEach((element)=>{
            element.pause();
        })
    }
    setTimeout(()=>{
        const nextbuttons = document.querySelectorAll('.carousel-control-next-icon');
        nextbuttons.forEach((elem)=>{
            elem.addEventListener("click", stopVideos)})
    }, 1000)
    const closeFullScreen = ()=>{
        stopVideos();
        let story = document.getElementsByClassName("story")
        for(let i = 0; i < story.length; i++){
            let element = story[i];
            if(element.classList.contains("showFullStory")){
                element.classList.remove("showFullStory")
            }
        }      
    }
    return(
        <section className="storiesSection">
            <section id="loading" style={{display: isLoading}}>
                <img src={loading} alt="loading"/>
            </section>
            {
            active && 
            <section className="addStories">
                <input accept="video/*" id='story' type='file' style={{display:"none"}} onChange={(e)=>{HandleStoryAdd(e)}}/>
                <label htmlFor='story'><Icon.PlusCircle id='addstorybutton'/></label>
                </section>
            }
            {
                (stories !== null || stories!== "") && distinct.map((val, key)=>{
                    let username = val.Username
                    if(username !== null){
                        let profile  = `https://future-dev.tech/server/ProfileImage/${username}/profile.jpg`;
                        
                        const userStories = stories.filter((array)=>array.Username === username)
                        return(
                            <section className="story" key={key} onClick={openStory} id={username}>
                            <Carousel interval={storyFullScreen}>
                            {
                                userStories.map((val, key)=>{
                                    let file     = `https://future-dev.tech/server/StoryVideos/${username}/${val.Filename}`
                                    
                                    return (
                                        <Carousel.Item key={key}> 
                                            <section className="closeFullScreen" onClick={closeFullScreen}><Icon.XCircle/></section>
                                            <section className="video">{val.Username}
                                            <video src={file} controlsList="nodownload" data-type="video" data-for={val.Username} onEnded={changeSlide}/> 
                                            </section>                            
                                            <section className="footerElement" style={{position: "absolute", right: "0", bottom: "0", color: "white", zIndex: "9999"}}>
                                                {val.Total_Views}
                                            </section>
                                            <section className="footerElement" style={{position: "absolute", right: "30px", bottom: "0", color: "white", zIndex: "9999"}}>
                                                <Icon.ShareFill color="white" onClick={()=>{window.location = `./SingleStory?Id=${val.Id}`;}}/>
                                            </section>
                                        </Carousel.Item>                                   
                                    )
                                })
                            }
                            </Carousel>
                                <section className="storyFooter">
                                    <img width="20" height="20" src={profile} style={{left: "5px", bottom: "5px"}} alt="profile"/>
                                    <section></section>
                                    <section></section>
                                </section>
                            </section>
                        )
                    }else{
                        return ""
                    }
                })
            }    
        </section>
    )
}