import { useEffect, useState } from "react"
import Server from "./../../../Server"
import * as Icon from "react-bootstrap-icons"

export default function Users(){
    const [users, setUsers] = useState([]);
    const [searchUser, setSearchUser] = useState("");
    const Direct = (username)=>{
        localStorage.setItem("selectedUsername", username)
        window.location = "/Manage/SelectedUser";
    }

    useEffect(()=>{
            Server.get("/Manage/GetAllUsers")
            .then(res=>setUsers(res.data))
            .catch(res=>console.error(res))
    }, [])
    const showAll = ()=>{
        Server.get("/Manage/GetAllUsers")
        .then(res=>setUsers(res.data))
        .catch(res=>console.error(res))
    }
    const handleChange = (e)=>{
        const { value } = e.target;
        setSearchUser(value);
    }
    const submitSearch = ()=>{
        Server.post("/Manage/GetAUser", {Username : searchUser})
        .then(res=>setUsers(res.data))
        .catch(res=>console.error(res))
    }
    return (
        <section className="table-responsive-sm">
            <section className="d-flex p-4">
                <input value={searchUser} className="form-control" placeholder="Search..." onChange={handleChange}/>
                <button className="btn btn-info ms-1" onClick={submitSearch}><Icon.Search/></button>
            </section>
            <button className="btn btn-info" onClick={showAll}>Show All</button>
        <table className="table table-striped table-hover table-bordered table-sm">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
            {
                users.map((val, index)=>{
                    return(
                        <tr key={index}>
                        <td className="Id">
                            {val.Id}
                        </td>
                        <td className="Name">
                            {val.Name || "N/A"}
                        </td>
                        <td>
                            {val.Username}
                        </td>
                        <td>
                            {val.Email}
                        </td>
                        <td>
                            {
                               val.Role === 1 ? "Owner":""
                            }
                            {
                                val.Role === 2 ? "Admin":""
                            }
                            {
                                val.Role === 3 ? "Business Man":""
                            }
                            {
                                val.Role === 4 ? "Marketer":""
                            }
                            {
                                val.Role === 5 ? "Delivery":""
                            }
                            {
                                val.Role === 6 ? "Regular":""
                            }
                        </td>
                        <td>
                            <section>
                                <Icon.InfoCircle className="Edit text-primary me-2" onClick={()=>{Direct(val.Username)}}/>
                            </section>
                        </td>
                    </tr>
                    )
                })
            }
                </tbody>
            </table>
            </section>
    )
}