import { useEffect, useState } from "react"
import Server from "./../../../Server"
export default function EditTax(){
    const [detail, setDetail] = useState([]);
    const [tax, setTax] = useState(0);
    let selectedUser = sessionStorage.getItem("selectedUsername")
    useEffect(()=>{
        Server.post("/Tax/UserDetail", {username: selectedUser})
        .then(res=>{
            setDetail(res.data)
            setTax(res.data[0].Tax)
        })
        .catch(res=>console.error(res))
    }, [selectedUser])

    const UpdateTax = (Username)=>{
            Server.post("/Tax/Update", {Username: Username, Tax: tax}, {timeout: 15000}).then(
                res=>{
                    if(res.data === "updated"){
                        alert("Updated");
                    }
                }
            )
            .catch(res=>console.error(res))
    }
    const handleChange = (e)=>{
        const { value } = e.target;
        setTax(value);
    }
    return (
        <section className="table-responsive-sm">
            <button className="btn btn-primary mt-1" onClick={()=>{window.location="./../Manage"}}>Go back</button>
        {
            detail.length > 0 && detail?.map((val, index)=>{
                return(
                    <table key={index} className="table table-striped table-hover table-bordered">
                        <thead>

                        </thead>
                        <tbody>
                        {
                            Object.entries(val).map(([key, value])=>{
                                return(
                                <tr key={key}>
                                    <th>{key}</th>
                                    <td>{key !== "Tax" ? value: <input min="0" step="1" value={tax} onChange={handleChange} style={{textAlign: "center"}}/>}</td>
                                </tr>
                                )
                            })
                        }
                            <tr>
                                <th>Actions</th>
                                <td>
                                    <section>
                                        <button className="btn btn-primary ms-1" onClick={()=>{UpdateTax(val.Username)}}>Update Tax</button>
                                    </section>
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>
                )
            })
        }
        </section>
    )
}