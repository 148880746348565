import { useState, useEffect } from "react";
import Server from "./../../../Server"
export default function Deliveries(){
    const [detail, setDetail] = useState([])
    useEffect(()=>{
        Server.post("/Manage/Delivery_Status").then(res=>{
            setDetail(res.data)
        })
    }, [])
    const update = (id)=>{
        Server.post("/Manage/Updating_Delivery_Status", { Id: id }, {timeout: 30000}).then(
            res=>{
                if(res.data === "updated"){
                    window.location.reload()
                }
            }
        )
    }
    return(
        <section className="table-responsive-sm">
            <table className="table table-striped table-hover table-bordered table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Total Sold</th>
                        <th>Tax</th>
                        <th>Shopper</th>
                        <th>Shopper Title</th>
                        <th>Shopper Phone</th>
                        <th>Delivered By</th>
                        <th>Delivered At</th>
                        <th>Profit</th>
                        <th>Total Profit</th>
                        <th>Total Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        detail.length > 0 && detail.map((elem, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{elem.Id}</td>
                                    <td>{elem.Title}</td>
                                    <td>{elem.Price}</td>
                                    <td>{elem.Total_Sold}</td>
                                    <td>{elem.Tax}</td>
                                    <td>{elem.ShopperUsername}</td>
                                    <td>{elem.ShopperTitle}</td>
                                    <td>{elem.ShopperPhone}</td>
                                    <td>{elem.Delivered_By}</td>
                                    <td>{elem.Delivered_Date.toLocaleString()}</td>
                                    <td>{elem.Profit}</td>
                                    <td>{(elem.Total_Sold * elem.Profit).toLocaleString()}</td>
                                    <td>{(elem.Total_Sold * elem.Price).toLocaleString()}</td>
                                    <td>{elem.Status}</td>
                                    <td>{elem.Status === "Pending" ? <button className="btn btn-success" onClick={()=>{update(elem.Id)}}>Received</button> : <button className="btn btn-danger" disabled="true">Received</button>}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </section>
    )
}