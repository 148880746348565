import { useState } from "react"
import Server from "./../Server"
import "./../css/Post.css"
import Swal from "sweetalert2";
export default function Post(props){
    let alreadySeen = localStorage.getItem("seenPostInfo");
    if(!alreadySeen){
        Swal.fire({
            icon: "info",
            title: "بڵاو کردنەوەی کاڵا",
            text: "لێرەدا ئەتوانیت کاڵاکانت بفرۆشیت، بۆ سەلامەتی هەژمارەکەت، تکایە زانیاری نا درووست یاخووت نەشیاو لێرەدا دامەنێ",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            },
            showCancelButton: false,
            confirmButtonText: "رازیم",
          })
          localStorage.setItem("seenPostInfo", true)
    }
    const [formData, setFormData] = useState({
        Username: localStorage.getItem("Username"),
        Title: '',
        Description: '',
        Price: 0,
        Category: 'خواردەمەنی',
        Files: '',
        Condition: 'نوێ',
        noCondition: true,
        Quantity: 0,
        File : new FormData(),
        Role : localStorage.getItem("Role"),
        ShopperUsername : '',
        ShopperTitle : "",
        ShopperPhone : "",
        Profit : 0,
        Size : ""
    });
    const [showCondition, setShowCondition] = useState(false)
    const handleChange = (e)=>{
        try{
            const {name, value} = e.target;
            if(name === "Files"){
                setFormData({...formData, [File]:formData.File.append("file",e.target.files[0])})
            }
            const selectedOption = document.querySelector(`option[value='${value}']`)
            if(name === "Category" && selectedOption?.getAttribute("type") === "noCondition"){
                setShowCondition(false)
                setFormData({...formData, noCondition: true, [name]: value})
            }else if(name === "Category" && selectedOption?.getAttribute("type") !== "noCondition"){
                setShowCondition(true)
                setFormData({...formData, noCondition: false, [name]:value})
            }else{
                setFormData({...formData, [name]:value}) 
            }
        }catch{

        }
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        try{
            // Change hook into FormData.

            const fileData = new FormData();
            fileData.append("file", formData.File.getAll("file")[0]);
            fileData.append("Username", formData.Username);
            fileData.append("Title", formData.Title);
            fileData.append("Description", document.getElementById('description').value)
            fileData.append("Price", formData.Price);
            fileData.append("Category", formData.Category)
            fileData.append("Condition", formData.Condition)
            fileData.append("Quantity", formData.Quantity)
            fileData.append("Role", localStorage.getItem("Role"))
            fileData.append("noCondition", formData.noCondition)
            fileData.append("ShopperUsername", formData.ShopperUsername)
            fileData.append("ShopperTitle", formData.ShopperTitle)
            fileData.append("ShopperPhone", formData.ShopperPhone)
            fileData.append("Profit", formData.Profit)

          //  console.log(formData.File.getAll("file")[0])
            await Server.post("/Post/addPost", fileData)
            .then(res=>{
                if(res.data === "success"){
                    setFormData({
                        Username: localStorage.getItem("Username"),
                        Title: '',
                        Description: '',
                        Price: 0,
                        Category: 'خواردەمەنی',
                        Files: '',
                        Condition: 'نوێ',
                        Quantity: 0,
                        noCondition: false,
                        File : new FormData(),
                        Role : localStorage.getItem("Role"),
                        ShopperUsername : '',
                        ShopperTitle : "",
                        ShopperPhone : "",
                        Profit : 0,
                        Size : ""
                    })
                    Swal.fire({
                        title: "پۆست",
                        text: "کاڵاکەت بە سەرکەوتووی پۆست کرا",
                        icon: "success",
                        showClass: {
                          popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                          `
                        },
                        hideClass: {
                          popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                          `
                        }
                      })
                }
            })
            .catch(res=>console.log(res))
        }catch(e){
            console.error(e)
        }
    }
    return(
        <section dir="rtl">
            <section className="form-control">
                <section className="form-floating">
                    <input type="text" onChange={handleChange} value={formData.Title} className="form-control" placeholder="i" name='Title' required/>
                    <label>سەردێڕ</label>
                </section>
                <section className="form-floating">
                    <textarea rows="4" cols="50" type="text" id="description" className="form-control" placeholder="i" name='Description' required style={{height:"100px"}}></textarea>
                    <label>ناوەڕۆک</label>
                </section>
                <section className="form-floating">
                    <input type="number" min="0" step="250" onChange={handleChange} value={formData.Price} className="form-control" placeholder="i" name='Price' required/>
                    <label>نرخ</label>
                </section>
                <section className="form-floating">
                    <input onChange={handleChange} value={formData.Size} className="form-control" placeholder="i" name='Size'/>
                    <label>قەبارە</label>
                </section>
                <section className="d-flex justify-content-between align-items-center mt-2 mb-2">
                    <label>بەش</label>
                    <select style={{width: "70%", height:"40px"}} name="Category" onChange={handleChange} value={formData.Category}>
                        <optgroup label="خواردن">
                            <option value="خواردەمەنی" type="noCondition">خواردەمەنی</option>
                            <option value="خواردنی خێرا" type="noCondition">خواردنی خێرا</option>
                            <option value="سەوزە و میوە" type="noCondition">سەوزە و میوە</option>
                            <option value="گۆشتی مریشک و ئاژەڵ" type="noCondition">گۆشتی مریشک و ئاژەڵ</option>
                        </optgroup>
                        <optgroup label="شیرنەمەنی">
                            <option value="ماست" type="noCondition">ماست</option>
                            <option value="شیر" type="noCondition">شیر</option>
                            <option value="پەنیر" type="noCondition">پەنیر</option>
                            <option value="دۆ" type="noCondition">دۆ</option>
                        </optgroup>
                        <optgroup label='جل و بەرگ'>
                            <option value="جل و بەرگی پیاوان">جل و بەرگی پیاوان</option>
                            <option value="جل و بەرگی ئافرەتان">جل و بەرگی ئافرەتان</option>
                            <option value="جل و بەرگی مناڵان">جل و بەرگی مناڵان</option>
                            <option value="جلی وەرزشی">جلی وەرزشی</option>
                            <option value="پێڵاو">پێڵاو</option>
                            <option value="جلی سزتانە">جلی سزتانە</option>
                            <option value="جلی بەهار">جلی بەهار</option>
                            <option value="جلی هاوینە">جلی هاوینە</option>
                            <option value="جلی پاییز">جلی پاییز</option>
                        </optgroup>
                        <optgroup label='پێداویستی'>
                            <option value="پێداویستی وەرزشی">پێداویستی وەرزشی</option>
                            <option value="پێداویستی ناوماڵ">پێداویستی ناوماڵ</option>
                        </optgroup>
                        <optgroup label='جوانکاری'>
                            <option value="جوانکاری ناوماڵ">جوانکاری ناوماڵ</option>
                            <option value="شوشەوات">شوشەوات</option>
                        </optgroup>
                        <optgroup label="خانووبەرە">
                            <option value="خانوو بەکرێدان">خانوو بەکرێدان</option>
                            <option value="زەوی و زار">زەوی و زار</option>
                            <option value="موڵک و دووکان بۆ کرێ">موڵک و دووکان بۆ کرێ</option>
                        </optgroup>
                        <optgroup label="تەکنەلۆجیا">
                            <option value="لاپتۆپ">لاپتۆپ</option>
                            <option value="ئایپاد">ئایپاد</option>
                            <option value="موبایل">موبایل</option>
                            <option value="هێدفۆن">هێدفۆن</option>
                        </optgroup>
                        <optgroup label="بارهەلگر">
                            <option value="ئۆتۆمبێل">ئۆتۆمبێل</option>
                            <option value="ماتۆر">ماتۆر</option>
                        </optgroup>
                        <optgroup>
                            <option value="هیتر">هیتر</option>
                        </optgroup>
                    </select>
                </section>
                <section className="form-floating">
                    <input type="file" accept="image/*,video/*" onChange={handleChange} value={formData.Files} className="form-control" placeholder="i" name='Files' required/>
                    <label>File</label>
                </section>
                {
                    showCondition && (
                <section style={{justifyContent: "space-between", alignItems:"center", marginTop: "8px", marginBottom: "8px", display: "flex"}}>
                    <label>باری کاڵاکە</label>
                    <select style={{width: "70%", height:"40px"}} onChange={handleChange} name="Condition">
                        <option value="نوێ">نوێ</option>
                        <option value="بەکارهاتوو">بەکارهاتوو</option>
                        <option value="بەکار هاتوو وەک نوێ">بەکارهاتوو وەک نوێ</option>
                        <option value="کۆن">کۆن</option>
                    </select>        
                </section>
                    )
                }
                <section className="form-floating">
                    <input type="number" min="0" step="1" onChange={handleChange} value={formData.Quantity} className="form-control" placeholder="i" name='Quantity' required/>
                    <label>ژمارەی بەردەست</label>
                </section>
            {
                localStorage.getItem("Username") === "Daim shop marketer" &&
                <section>
                    <h3 className="mt-2">تایبەت بە Daim Shop</h3>
                    <section className="form-floating">
                        <input onChange={handleChange} value={formData.ShopperUsername} className="form-control" placeholder="i" name='ShopperUsername' required/>
                        <label>ناوی دووکاندار</label>
                    </section>
                    <section className="form-floating">
                        <input  onChange={handleChange} value={formData.ShopperTitle} className="form-control" placeholder="i" name='ShopperTitle' required/>
                        <label>ناوونیشانی دووکاندار</label>
                    </section>
                    <section className="form-floating">
                        <input onChange={handleChange} value={formData.ShopperPhone} className="form-control" placeholder="i" name='ShopperPhone' required/>
                        <label>ژمارەی تەلەڤۆن</label>
                    </section>
                    <section className="form-floating">
                        <input type="number" min="0" step="250" onChange={handleChange} value={formData.Profit} className="form-control" placeholder="i" name='Profit' required/>
                        <label>قازانج</label>
                    </section>
                </section>
            }
                <button onClick={handleSubmit} className="btn btn-success mt-2">بەڵاوکردنەوە</button>
            </section>
        </section>
    )
}