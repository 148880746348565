import { useEffect, useState } from "react";
import React from "react";
import Server from "./../Server";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import Logo from "./../Images/Markers/map-marker.png";
import "./../css/StartDelivery.css";
import Routing from "./Routing";
import PostDetails from "./PostDetails";
import QrScanner from "./QrScanner";
import Swal from "sweetalert2";
import * as Icon from "react-bootstrap-icons";
export default function StartDelivery() {
    const [barcode] = useState(localStorage.getItem("StartDelivery"));
    const [error, setError] = useState(null);
    const [startPosition, setStartPosition] = useState({ lat: 0, lng: 0 });
    const [endPosition, setEndPosition] = useState([{ lat: 0, lng: 0}]);
    const [readyToDistribute, setReadyToDistribute] = useState(false);
    const [detail, setDetail] = useState("");
    const [verify, setVerify] = useState([]);
    const [barcodeScanned, setBarcodeScanned] = useState([]);
    useEffect(() => {
        const location = async ()=>{
            await new Promise((resolve)=>{
                Server.post("/Delivery/getLocation", { Username:  localStorage.getItem("Username")}).then(
                    res => {
                        setEndPosition(res.data);
                    }
                );
                resolve();
            })
        }
        location();
        Server.post("/Delivery/Verify", { Username: localStorage.getItem("Username")}).then(res=>{
            setVerify(res.data);
        })
    }, []);
    const handleSuccess = (position) => {
        const { latitude, longitude } = position.coords;
        setStartPosition({ lat: latitude, lng: longitude });
    };

    const handleError = (error) => {
        setError(error.message);
    };
    useEffect(()=>{
        Server.post("/Delivery/UserLocation", {Username: localStorage.getItem("Username")}).then(res=>{
            const { Latitude, Longitude} = res.data[0];
            setStartPosition({lat: Latitude, lng: Longitude});
        });
    }, [])

    useEffect(() => {
        const watcher = navigator.geolocation.watchPosition(handleSuccess, handleError, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });

        return () => navigator.geolocation.clearWatch(watcher);
    }, []);


    const customIcon = new L.Icon({
        iconUrl: Logo,
        iconSize: [32, 32],
        iconAnchor: [16, 16] // Optional: Adjust if the icon is not positioned correctly
    });
    const stop = async ()=>{
        localStorage.setItem("StartDelivery", null);
        await new Promise(resolve=>{
            Server.post("/Delivery/Stop", {Barcode: barcode})
            resolve();
        })
        window.location = "./";
    }
    function ShowMap() {
        return (
            <section style={{width:"80vw", height:"400px", overflow:"hidden", margin:"0 auto"}}>
                <MapContainer center={startPosition} zoom={13}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={startPosition} icon={customIcon}>
                        <Popup>Start Position</Popup>
                    </Marker>
                    {
                        endPosition.map((route, index) => {
                            if (route.lat && route.lng) {
                                return (
                                    <React.Fragment key={index}>
                                        <Marker position={[route.lat, route.lng]} icon={customIcon}>
                                            <Popup>{route.Username}</Popup>
                                        </Marker>
                                        <Routing start={startPosition} end={route} />
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })
                    }
                </MapContainer>
            </section>
        );
    }
    const ready = ()=>{
        setReadyToDistribute(true)
        Server.post("/Delivery/ReadyToStart", {Barcode: barcode})
    }
    const loading = ()=>{
        Server.post("/Delivery/Loading", {Barcode: barcode, Username: localStorage.getItem("Username")}).then(
            res=>{
                setDetail(res.data)
            }
        )
    }
    const VirtualScan = (decodedText)=>{
        if(decodedText[0] === "D" && decodedText[1] === "S" && decodedText[decodedText.length -2] === "D" && decodedText[decodedText.length - 1] === "E"){
            new Promise((resolve)=>{
              Server.post("/Delivery/Finished", {Barcode: decodedText, Username: localStorage.getItem("Username")}).then(res=>console.log(res.data))
              const elements = document.getElementsByClassName(decodedText)
              for(let i = 0; i < elements.length; i++){
                elements[i].style.display="none";
                const username = elements[i].getAttribute("data-Username")
                const usernames = document.querySelectorAll(`[data-Username=${decodedText}]`)
                for(let j = 0; j < usernames.length; j++){
                  usernames[j].style.display = "none";
                } 
                
                Swal.fire({
                  title: "سکانەکە سەرکەوتوو بوو",
                  icon: "success",
                  showClass: {
                    popup: `
                      animate__animated
                      animate__fadeInUp
                      animate__faster
                    `
                  },
                  hideClass: {
                    popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster
                    `
                  }
                });
    
              }
              resolve()
            }) 
            //html5QrCode.clear();
          }
    }
    const FinishingAnOrder = (barcode)=>{
        if(barcodeScanned.includes(barcode)){
            Swal.fire({
                title: "ئەم بارکۆدە سکان کراوە پێشتر",
                icon: "success",
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                }
              });
        }else{
            VirtualScan(barcode)
            setBarcodeScanned([...barcodeScanned, barcode])

        }
    }
    const results = endPosition.reduce((acc, client) => {
        // Find all verifications for the current client
        const clientVerifications = verify.filter(scan => (scan.Requested === client.Username && scan.Quantity > 0));
        let total = 0;
        clientVerifications.forEach(elem => {
            total += elem.RequestedAmount * elem.Price;
        });
    
        // If there are verifications, create sections for them
        if (clientVerifications.length > 0) {
            // Create a section for the client's username (or other identifier)
            const clientLegendSection = (
                <section key={`${client.Username}-legend`} className="clientLegendSection">
                    <legend>{client.Username}</legend> {/* Assuming client.Username is the desired identifier */}
                    <p>{client.Phone}</p>
                    <a className="mb-2" href={"https://wa.me/"+client.Phone}><Icon.Whatsapp size={26} color="#25D366"/></a>
                    <a className="mb-2" href={"tel:"+client.Phone}><Icon.PhoneVibrate size={26}/></a>
                    <a className="mb-2" href={`skype:${client.Phone}?call`}><Icon.Skype size={26} color="#00AFF0"/></a>
                </section>
            );
            const clientSections = clientVerifications.map((elem, index) => (
                <section key={`${client.Username}-${index}`} className={elem.Barcode} data-Username={elem.Barcode}>
                    <fieldset>
                        <PostDetails list={[elem]} quantityAsked={elem.RequestedAmount}/>
                        <button className="btn btn-success" onClick={()=>{FinishingAnOrder(elem.Barcode)}}>گەیاندنی ئەم بەکارهێنەرەیە تەواو</button>
                    </fieldset>
                    <hr/>
                </section>
            ));
    
            // Add a section for total delivery
            const totalSection = (
                <section className="totalDeliverySection">
                    <p className="totalDelivery">{total.toLocaleString()}</p>
                </section>
            );
    
            // Add the client legend, total section, and the client sections to the accumulator
            acc.push(clientLegendSection, totalSection, ...clientSections);
        }
    
        return acc;
    }, []);
    
    
    return (
        <>
        <section id="list">
                {
                  //  readyToDistribute && <QrScanner/>
                }
        </section>
            {
                !readyToDistribute && <>
                    <h2>کاڵای پێویستت پێیە؟</h2>
                    <span>
                        <button className="btn btn-success me-2" onClick={ready}>بەڵێ</button>
                        <button className="btn btn-danger" onClick={loading}>نەخێر</button>
                    </span>
                    {
                        detail.length > 0 && detail.map((element, index)=>{                          
                            return(
                                <section key={index}>
                                    <PostDetails list={[element]} quantityAsked={element.total_quantity}/>
                                </section>
                            )
                        })
                    }
                </>
            }
            {
                readyToDistribute && ShowMap()
            }
            
            <section className="mt-3 d-flex justify-content-around">
                <button className="btn btn-danger" onClick={stop}>Stop</button>
            {
                error ? "" : ""
            }
            </section>
            {
                    readyToDistribute && results
            }
        </>
    );
}
