import { useEffect, useState } from "react";
import Server from "./../Server";
import PostDetails from "./PostDetails";
import Swal from "sweetalert2";
export default function Cart(){
    const [detail, setDetail] = useState([]);
    const [history, setHistory] = useState([]);
    const [emails, setEmails] = useState("");
    const [UnknownError, SetUnknownError] = useState("");
    const username = localStorage.getItem("Username");
    useEffect(()=>{
        Server.post("/Cart/getCart", {Username: username}).then(res=>{
            setDetail(res.data);
        });
        Server.post("/Cart/getStaged", {Username: username}).then(res=>{
            setHistory(res.data)
        })
    }, [username])
    useEffect(() => {
        const emailSet = new Set(emails.split(', ').filter(email => email)); // Initialize Set with current emails
    
        const fetchEmails = async () => {
            for (const elem of detail) {
                const res = await Server.post("/Cart/getEmailsForNotification", { Id: elem.Id });
                res.data.forEach(item => {
                    emailSet.add(item.Email);
                    emailSet.add(item.Delivery);
                });
            }
            setEmails(Array.from(emailSet).join(', '));
        };
    
        if (detail?.length > 0) {
            fetchEmails();
        }
    }, [detail, emails]);
    
    const [info, setInfo] = useState("");
    const updateInfo = async ()=>{
        let array = [];
        detail.forEach((elem) => {
            let id = elem.Id;
            let quantity = elem.Chosen;
            array.push({ Id: id, Quantity: quantity });
        });
        setInfo(array);
    }
    const submitOrder = async () => {
        if(detail.length > 0){
            updateInfo(); 
        }
    }
    useEffect(()=>{
        if(info.length > 0){
            const askForLocationPermission = async () => {
                try {
                  const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
                  if (permissionStatus.state === 'prompt') {
                      // Prompt the user for permission
                      if(navigator.geolocation){
                        navigator.geolocation.getCurrentPosition((e) => {
                            const newCoordinates = [e.coords.latitude, e.coords.longitude];

                      Server.post("/Manage/UpdateMap", {Username: localStorage.getItem("Username"), Lat: newCoordinates[0], Long: newCoordinates[1]}).then(
                        res=>{
                            if(res.data === "Successfull"){
                                Server.post("/Cart/submitOrder", { Username: localStorage.getItem("Username"), Info: info })
                                .then(res => {
                                    if (res.data === "Confirmed") {
                                        // send Email to the Marketer and the deliery
                                        const data = {
                                            To : emails,
                                            Subject : "داواکاری گەیاندنت بۆ هاتووە",
                                            HTMLContent : `
                                            <section style='text-align:right'>
                                            <h2>سڵاو</h2>
                                            <p>${username}:بەکارهێنەر </p>
                                            <p>داوای چەند کاڵایێکی کردووە بۆ گەیاندن</p>             
                                            <a href="https://daimshop.future-dev.online/Delivery">سەیرکردنی کاڵاکان</a>
                                            </section>
                                            `
                                        }
                                        Server.post("/Email/send-email", data).then(res=>{console.log(res.data)});
                                        window.location.reload();
                                    }
                            });
                            }
                        }
                    )
                });}
                    } else if (permissionStatus.state === 'granted') {
                        navigator.geolocation.getCurrentPosition((e) => {
                        const newCoordinates = [e.coords.latitude, e.coords.longitude];
                       
                    Server.post("/Manage/UpdateMap", {Username: localStorage.getItem("Username"), Lat: newCoordinates[0], Long: newCoordinates[1]}).then(
                        res=>{
                            if(res.data === "Successfull"){
                                Server.post("/Cart/submitOrder", { Username: localStorage.getItem("Username"), Info: info })
                                .then(res => {
                                    if (res.data === "Confirmed") {
                                        // send Email to the Marketer and the deliery
                                        const data = {
                                            To : emails,
                                            Subject : "داواکاری گەیاندنت بۆ هاتووە",
                                            HTMLContent : `
                                            <section style='text-align:right'>
                                            <h2>سڵاو</h2>
                                            <p>${username}:بەکارهێنەر </p>
                                            <p>داوای چەند کاڵایێکی کردووە بۆ گەیاندن</p>             
                                            <a href="https://daimshop.future-dev.online/Delivery">سەیرکردنی کاڵاکان</a>
                                            </section>
                                            `
                                        }
                                        Server.post("/Email/send-email", data).then(res=>{console.log(res.data)});
                                        window.location.reload();
                                    }
                            });
                            }
                        }
                    )
                });
                    // Permission already granted, you can proceed
                  } else {
                    // Permission denied
                    console.log('Geolocation permission denied by user.');
                  }
                } catch (error) {
                  // Handle errors
                  ///////
                  SetUnknownError("Error: " + error);
                }
              };
          
            askForLocationPermission();
        }
    }, [info, emails, username])
    
    const viewOrder = (username, date, barcode, status)=>{
        localStorage.setItem("Delivery_Status_For_Cart", status);
        window.location = `./ViewOrder?Username=${username}&Date=${date}&Barcode=${barcode}`;
    }
    /*
    setTimeout(()=>{
        let data = detail.length;
        if(data === 0){
            Swal.fire({
                icon: "error",
                title: "هیچ کاڵایێکت هەڵنەبژاردووە",
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                },
                showCancelButton: false,
                confirmButtonText: "باشە",
              }).then((response)=>{
                if(response.isConfirmed){
                    window.location = "./";
                }
              })
        }
    }, 3000)
    */
    return(
        <>
            {
                (detail != null && detail.length > 0) && detail.map((element, index)=>{                  
                    return(
                        <section key={index} style={{position: "relative"}}>
                            <PostDetails list={[element]} RemvoeFromCart={element.Id}/>
                           {/* <input data-for={element.Id} style={{width: "150px"}} type="number" placeholder="Quantity" min="1" step="1" max={element.Quantity} required/> */}
                        </section>
                    )
                })
            }
            <button className="btn btn-success mt-2" onClick={submitOrder}>داواکردن</button>
            <p style={{color:"red"}}>{UnknownError}</p>
        <table className="table table-striped">
            <caption style={{captionSide:"top", textAlign:"center"}}>ئۆردەرەکانم</caption>
            <thead>
                <tr>
                    <th>ژمارەی نهێنی</th>  
                    <th>ژمارەی کاڵا</th>
                    <th>باروودۆخ</th>
                    <th>کردار</th>
                </tr>
            </thead>
            <tbody>
                {
                    history.length > 0 && history.map((element, key)=>{
                        return(
                            <tr key={key}>
                                <td>{element.Barcode}</td>                        
                                <td>{element.total}</td>
                                <td>{element.Status}</td>
                                <td><button className="btn btn-success" onClick={()=>{viewOrder(element.Username, element.Submit_Date, element.Barcode, element.Status)}}>بینین</button></td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
        </>
    )
}