import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Server from "./../Server";

export default function Confirm(){
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const Delivery = params.get('Delivery');
    const Marketer = params.get('Marketer');

    const [detail, setDetail] = useState("");
    useEffect(()=>{
        Server.post("/Manage/Confirm", {Marketer: Marketer}).then(res=>{setDetail(res.data)})
    }, [Marketer])
    const LogoImg = `https://future-dev.tech/server/Promotions/${detail[0]?.Username}/${detail[0]?.Logo}`;
    
    const accept = ()=>{
        Server.post("/Manage/Confirmed", {Delivery: Delivery, Marketer: Marketer, Accepted: "Yes"}).then(res=>{
            if(res.data === "Confirmed"){
                window.location = "./"
            }
        })
    }

    const deny = ()=>{
        Server.post("/Manage/NotConfirmed", {Delivery: Delivery, Marketer: Marketer}).then(res=>{
            if(res.data === "Confirmed"){
                window.location = "./"
            }
        })
    }
    return(
            <>
            <table dir="rtl" className='table table-striped'>
                <tbody>
                    <tr>
                        <th>ناوی بەکارهێنەر</th>
                        <td>{detail[0]?.Username}</td>
                    </tr>
                    <tr>
                        <th>ناوونیشان</th>
                        <td>{detail[0]?.Title}</td>
                    </tr>
                    <tr>
                        <th>کورتە</th>
                        <td>{detail[0]?.Description}</td>
                    </tr>
                    <tr>
                        <th>شار</th>
                        <td>{detail[0]?.City}</td>
                    </tr>
                    <tr>
                        <th>وێنەی فرۆشگاکە</th>
                        <td><img style={{width: "100px", objectFit:"contain"}} src={LogoImg} alt="logo"/></td>
                    </tr>
                </tbody>
            </table>
            <section className="mt-3">
                <button className='btn btn-info me-3' onClick={accept}>قبووڵی ئەکەم</button>
                <button className='btn btn-danger' onClick={deny}>ڕەتی ئەکەمەوە</button>
            </section>
            </>
    )
}