import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Login from './Pages/Login';
import Footer from './Pages/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import Aside from './Pages/Aside';
import Markets from './Pages/Markets';
import Signup from './Pages/Signup';
import Post from "./Pages/Post";
import Manage from './Pages/Manage';
import UserDetail from './Pages/Manager/Functions/UserDetail';
import PromotionDetail from './Pages/Manager/Functions/PromotionDetail';
import ProfileSetting from './Pages/ProfileSetting';
import SearchBar from './Pages/SearchBar';
import Favorites from './Pages/Favorites';
import RequestPosition from './RequestPosition';
import MarketShop from './Pages/MarketShop';
import "./css/global.css"; 
import SinglePost from './Pages/SinglePost';
import SingleStory from "./Pages/SingleStory";
import Search from './Pages/Search';
import Confirm from './Pages/Confirm';
import Cart from './Pages/Cart';
import ViewOrder from './Pages/ViewOrder';
import Delivery from './Pages/Delivery';
import Profile from './Pages/Profile';
import StartDelivery from './Pages/StartDelivery';
import QrScanner from './Pages/QrScanner';
import EditTax from "./Pages/Manager/Functions/EditTax"
import MarketDetail from './Pages/MarketDetail';
import Feedback from './Pages/Feedback';
import ReadyToUpdate from './Pages/ReadyToUpdate';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <SearchBar/>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App/>}/>
        <Route path='Login' element={<Login/>}/>
        <Route path='Signup' element={<Signup/>}/>
        <Route path='Shop' element={<Markets title='فرۆشگاکان' Data='Markets'/>}/>
        <Route path='Post' element={<Post Role={localStorage.getItem("Role")}/>}/>
        <Route path='Manage' element={<Manage Role={localStorage.getItem("Role")}/>}/>
        <Route path='Community' element={<Markets title='فرۆشگای کۆمەڵگە' Data='Community'/>}/>
        <Route path='ProfileSetting' element={<ProfileSetting/>}/>
        <Route path='/Manage/SelectedUser' element={<UserDetail/>}/>
        <Route path="/Manage/PromotionDetail" element={<PromotionDetail/>}/>
        <Route path="Favorite" element={<Favorites/>}/>
        <Route path="Promotion" element={<RequestPosition/>}/>
        <Route path="MarketShop" element={<MarketShop/>}/>
        <Route path="SinglePost" element={<SinglePost/>}/>
        <Route path="SingleStory" element={<SingleStory/>}/>
        <Route path="Search" element={<Search/>}/>
        <Route path="Confirm" element={<Confirm/>}/>
        <Route path="Cart" element={<Cart/>}/>
        <Route path="ViewOrder" element={<ViewOrder/>}/>
        <Route path='Delivery' element={<Delivery/>}/>
        <Route path="Profile" element={<Profile/>}/>
        <Route path="StartDelivery" element={<StartDelivery/>}/>
        <Route path="Scanner" element={<QrScanner/>}/>
        <Route path="EditTax" element={<EditTax/>}/>
        <Route path="MarketDetail" element={<MarketDetail/>}/>
        <Route path="Feedback" element={<Feedback/>}/>
        <Route path="ReadyToUpdate" element={<ReadyToUpdate/>}/>
      </Routes>
    </BrowserRouter>
    <Aside/>
    <Footer/>
  </React.StrictMode>
);
