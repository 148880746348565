import { useEffect, useState } from "react"
import Server from "./../../../Server"
import * as Icon from "react-bootstrap-icons"

export default function Tax(){
    const [detail, setDetail] = useState([])
    useEffect(()=>{
        Server.post("/Tax/getPosts").then(res=>setDetail(res.data))
    }, [])
    console.log(detail)
    const Direct = (username)=>{
        sessionStorage.setItem("selectedUsername", username);
        window.location = '/EditTax';
    }
    return(
        <section className="table-responsive-sm">
        <table className="table table-striped table-hover table-bordered table-sm">
            <thead>
                <tr>
                    <th>Photo</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th>Tax</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    detail.length > 0 && detail.map((elem, index)=>{
                        let profile  = `https://future-dev.tech/server/ProfileImage/${elem.Username}/profile.jpg`;
                        return(
                            <tr key={index}>
                                <td>
                                    {
                                        <img style={{width: "28px", }} src={profile} alt="Profile"/>
                                    }
                                </td>
                                <td>
                                    {elem.Username}
                                </td>
                                <td>
                                    {elem.Role}
                                </td>
                                <td>
                                    {elem.Tax}
                                </td>
                                <td>
                                    <section>
                                        <Icon.InfoCircle className="Edit text-primary me-2" onClick={()=>{Direct(elem.Username)}}/>
                                    </section>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
        </section>
    )
}