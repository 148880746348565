import L from 'leaflet';
import 'leaflet-routing-machine';
import { useEffect } from "react";
import { useMap } from 'react-leaflet';

export default function Routing({ start, end }) {
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        // Remove any existing routing controls first
        map.eachLayer(layer => {
            if (layer instanceof L.Routing.Control) {
                map.removeControl(layer);
            }
        });

        const routingControl = L.Routing.control({
            waypoints: [
                L.latLng(start.lat, start.lng),
                L.latLng(end.lat, end.lng)
            ],
            routeWhileDragging: true,
            showAlternatives: false,
            lineOptions: {
                styles: [{ color: '#6FA1EC', weight: 3 }]
            },
            fitSelectedRoutes: false, // Set to false to prevent auto-focusing on the route
            show: false
        }).addTo(map);

        // Manually set the map view to the start position
        map.setView(L.latLng(start.lat, start.lng), 19);

        return () => {
            map.removeControl(routingControl);
        };
    }, [map, start, end]);

    return null;
}