import { useState } from "react";
import Server from "./Server";
import Swal from "sweetalert2";
export default function RequestPosition() {
    let seenRequestPosition = sessionStorage.getItem("seenRequestPosition");
    if(!seenRequestPosition){
        Swal.fire({
            icon: "info",
            title: "داواکردنی پلە",
            text: "لێرەدا ئەتوانی داوای پلە بکەیت لە دایم بازاڕەوە. ئەتوانیت وەکوو فرۆشگا، گەیاندنکار، یاخووت وەکوو ئەدمین پلە وەلگریت",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            },
            showCancelButton: false,
            confirmButtonText: "ئامادەم",
          })
          sessionStorage.setItem("seenRequestPosition", true)
    }
    const username = localStorage.getItem("Username");
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        Username: username,
        Title: "",
        Description: "",
        City: "",
        Logo: null,
        Identification: null,
        File: new FormData(),
        Role: "Delivery",
    });
    const [hide, setHide] = useState("none");

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if(name === "Role"){
            if(value === "Delivery"){
                setHide("none");
            }else{
                setHide("block")
            }
        }
            try {
                if (name === "Logo" || name === "Identification") {
                    formData.File.append(name, files[0]);
                } else {
                    setFormData({ ...formData, [name]: value });
                }
            } catch (e) {
                console.error(e.message);
            }
        
        console.log(formData)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {         
            const fileData = new FormData();
            fileData.append("Username", username);
            fileData.append("Title", formData.Title);
            fileData.append("Description", formData.Description);
            fileData.append("City", formData.City);
            fileData.append("LogoFile", document.querySelector('input[name="Logo"]').files[0]);
            fileData.append("IdFile", document.querySelector('input[name="Identification"]').files[0]);
            fileData.append("Role", formData.Role);

            const response =  await Server.post("/Permission/AddRequest", fileData, { timeout: 60000 });
            if(response.data === "Success") {
                window.location.reload();
            }
        } catch (e) {
            console.error(e);
            setError(e.message)
        }
    };
    
    const style = {
        width: "80%",
        margin: "0 auto"
    }
    return (
        <section style={style}>
            <section className="form-floating">
                <input
                    type="text"
                    name="Username"
                    value={username}
                    readOnly={true}
                    className="form-control"
                    placeholder="i"
                    required
                />
                <label>Username:</label>
            </section>
            <section className="form-floating mt-1" style={{display : hide}}>
                <input
                    type="text"
                    name="Title"
                    value={formData.Title}
                    onChange={handleChange}
                    className="form-control"                  
                    placeholder="i"
                    required
                />
                <label>Title:</label>
            </section>
            <section className="form-floating mt-1" style={{display : hide}}>
                <input
                    type="text"
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="i"
                    required
                />
                <label>Description:</label>
            </section>
            <section className="form-floating mt-1">
                <input
                    type="text"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="i"
                    required
                />
                <label>City:</label>
            </section>
            <section className="form-floating mt-1" style={{display : hide}}>
                <input
                    type="file"
                    name="Logo"
                    accept="image/*"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="i"
                    required
                />
                <label>Logo</label>
            </section>
            <section className="form-floating mt-1">
                <input
                    type="file"
                    name="Identification"
                    accept="image/*"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="i"
                    required
                />
                <label>Identification</label>
            </section>
            <section className="form-floating mt-1">
                <select name="Role" onChange={handleChange} className="form-control">
                    <option value="Delivery">Delivery</option>
                    <option value="Marketer">Marketer</option>
                    <option value="Business-Man">Business Man</option>
                    <option value="Admin">Admin</option>
                </select>
            </section>
            <button className="btn btn-primary mt-3" onClick={handleSubmit}>
                Request
            </button>
            <p>{error}</p>
        </section>
    );
}
