import { useEffect, useState } from "react"
import Server from "./../Server";
import PostDetails from "./PostDetails";

export default function Profile(){
    const [detail, setDetail] = useState("");
    const username = localStorage.getItem("Username");
    useEffect(()=>{
        Server.post("/Profile/getPosts", { Username : username }).then(res=>setDetail(res.data))
    }, [username])
    return(
        <>
            {
                detail.length > 0 && detail.map((element, key)=>{
                    return (
                        <section key={key}>
                            <PostDetails list={[element]} owner={element.Id} ReadyToEdit={true}/>
                        </section>
                    )
                })
            }
        </>
    )
}