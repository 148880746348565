import { useEffect, useState } from "react"
import Server from "./../Server";
export default function Delivery(){
    const [detail, setDetail] = useState("");
    useEffect(()=>{
        if(localStorage.getItem("StartDelivery") !== "null"){
            window.location = './StartDelivery';
        }
        Server.post("/Delivery/getDeliveries", {Username: localStorage.getItem("Username")}).then(res=>setDetail(res.data)).catch(res=>{
            alert("Error: "+ res)
        })
    }, [])
    const Distance = (distance)=>{
            try{
                const metric = Math.round(distance * 1000.00) / 1000.00    
                if(metric > 1){
                    return metric + " KM";
                }else{
                    return metric * 1000.00 + " M";
                }
            }catch{
                return "Unkown";
            }
    }
    const StartDelivery = (barcode) =>{
        localStorage.setItem("StartDelivery", barcode)
        window.location = `./StartDelivery`;
    }
    return(
        <>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>ناوی بەکارهێنەر</th>
                        <th>تەواوی کاڵاکان</th>
                        <th>مەودای نێوانتان</th>
                        <th>باروودۆخ</th>
                        <th>کردار</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        detail?.length > 0 && detail.map((element, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{element.Username}</td>
                                    <td>{element.Quantity}</td>
                                    <td>{Distance(element.distance)}</td>
                                    <td>{element.Status}</td>
                                    <td><button className="btn btn-success" onClick={()=>{StartDelivery(element.Barcode)}}>گەیاندن</button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}