import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeComponent = ({ data }) => {
  return (
    <div>
      <QRCode value={data} style={{width: "300px", height: "300px"}}/>
    </div>
  );
};

export default QRCodeComponent;
