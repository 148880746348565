import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Server from "./../Server";
import PostDetails from './PostDetails';
import QRCodeComponent from './Qrcode';
export default function ViewOrder(){
    const [detail, setDetail] = useState();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const date = params.get('Date');
    const username = params.get('Username')
    const barcode  = params.get('Barcode')
    useEffect(()=>{
        Server.post("/Cart/ViewOrder", {date: date, Username: username}).then(res=>{
            setDetail(res.data)
        })
    }, [date, username])

    const emptyCart = (date)=>{
        if(username === localStorage.getItem("Username")){
            const data = prompt("دڵنیایت لە پاشتگەست بوونەوەکەت؟ بنووسە 'بەڵێ' گەر ئەتەوێ پاشتگەست ببیەوە")
            if(data === "بەلێ" || data === "بەڵێ" || data === "بەلی"){
                Server.post("/Cart/DeleteOrder", {Username: localStorage.getItem("Username"), date : date}).then(res=>{
                    if(res.data === "Confirmed"){
                        window.location = "./Cart";
                    }
                })
            }else{
                window.location.reload();
            }
        }
    }
    const status = localStorage.getItem("Delivery_Status_For_Cart");
    return(
        <>
        <section style={{paddingTop: "2em"}}>
            <QRCodeComponent data={barcode}/>
        </section>
           {
            detail?.length > 0 && <PostDetails list={detail}/>
           }
           <button className='btn btn-danger' onClick={()=>{emptyCart(detail[0].Submit_Date)}} disabled={status === "ئامادە نیە" ? false : true}>پاشتگەست بوونەوە</button>
        </>
    )
}