import "./../css/Registration.css";
import React, { useState } from 'react';
import Server from "./../Server";
import Swal from "sweetalert2";
export default function Login() {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [formData, setFormData] = useState({
    email: '',
    password:'',
    remember:'',
  });
  const [loginErr, setLoginErr] = useState("none");
  if(loginErr === "block"){
    Swal.fire({
      icon: "error",
      title: "ناوی بەکار هێنەر یاخووت تێپەڕ وشە هەڵەیە",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      },
      showCancelButton: false,
      confirmButtonText: "دووبارە هەوڵدان",
    }).then(()=>{
      setLoginErr("none");

    })
  }
  const handleLogin = async (e) => {
    e.preventDefault();
    try{
      const send = await Server.post("/Profile/Login", formData);
      send.data ? setLoginErr("none") : setLoginErr("block");
      let sessions = send.data;
      localStorage.setItem("Name", sessions.Name);
      localStorage.setItem("Username", sessions.Username);
      localStorage.setItem("Email", sessions.Email);
      localStorage.setItem("Phone", sessions.Phone);
      localStorage.setItem("Dob", sessions.Dob);
      localStorage.setItem("Role", sessions.Role);
      if(sessions === false){

      }else{
        if(sessions.Username){
          window.location = "./";   
        }
            
      }
    }catch(err){
      console.log(err)
    }
    // Add authentication logic here
    // Typically, you would make an API request to verify credentials

    // If authentication is successful, redirect the user
  };

  return (
    <section style={{marginTop:"50%"}}>
      <h2>Login</h2>
      <p style={{color:"red", display:"none"}}>Username or Password is wrong!</p>
      <form onSubmit={handleLogin} className="form-control">
        <section className="form-floating">
          <input
            type="text"
            name='email'
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Email or Username:</label>
        </section>
        <section className="form-floating">
          <input
            type="password"
            name='password'
            value={formData.password}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Password:</label>
        </section>
        <section>
            <input type='checkbox' onChange={handleChange} name='remember' value={formData.remember}/>
            <label htmlFor='remember'>Remember this device?</label>
        </section>
        <button className="btn btn-primary mt-2" type="submit">Login</button>
        <p>Don't have an account? <a href='Signup'>Creat one</a></p>
      </form>
    </section>
  );
}
