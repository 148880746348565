import React, { useEffect, useRef, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import "./../css/qrScanner.css";
import Server from "./../Server";
import Swal from 'sweetalert2';
const QrScanner = () => {
  const qrRef = useRef(null);
  const [scanner, setScanner] = useState(null);

  useEffect(() => {
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      console.log(`QR Code detected: ${decodedText}`);
      // Handle the decoded text or result here
      if(decodedText[0] === "D" && decodedText[1] === "S" && decodedText[decodedText.length -2] === "D" && decodedText[decodedText.length - 1] === "E"){
        new Promise((resolve)=>{
          Server.post("/Delivery/Finished", {Barcode: decodedText, Username: localStorage.getItem("Username")}).then(res=>console.log(res.data))
          const elements = document.getElementsByClassName(decodedText)
          for(let i = 0; i < elements.length; i++){
            elements[i].style.display="none";
            const username = elements[i].getAttribute("data-Username")
            const usernames = document.querySelectorAll(`[data-Username=${username}]`)
            for(let j = 0; j < usernames.length; j++){
              usernames[j].style.display = "none";
            } 
            
            Swal.fire({
              title: "سکانەکە سەرکەوتوو بوو",
              icon: "success",
              showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `
              }
            });

          }
          resolve()
        }) 
        //html5QrCode.clear();
      }
    };
    const qrCodeErrorCallback = (errorMessage) => {
      console.error(errorMessage);
    };

    const html5QrCode = new Html5QrcodeScanner(
      qrRef.current.id,
      config,
      false
    );

    html5QrCode.render(qrCodeSuccessCallback, qrCodeErrorCallback);
    setScanner(html5QrCode)
    return () => {
        if(scanner){
            scanner.clear();
        }
    };
  }, []);

  return <div id="qr-reader" ref={qrRef}></div>;
};

export default QrScanner;
